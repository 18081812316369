import React, { useCallback } from 'react';
import {
  PlaidEmbeddedLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkStableEvent,
  PlaidLinkOnEventMetadata,
} from 'react-plaid-link';
import { useObserver } from 'mobx-react-lite';

import { useStores } from 'contexts/stores';
import { useStartAggregatorLinkQuery } from 'types/graphql';

import { AGGREGATORS, DUTCHIE_PAY_QUERY_PARMAS_KEY, PLAID_STORAGE_KEY, REDIRECT_URL } from 'src/payments/constants';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { usePlaidLinkUtils } from 'src/payments/hooks/use-plaid-link-utils/use-plaid-link-utils';

import { useDutchiePayAnalytics } from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';

export const PlaidEmbeddedSearch = ({
  handleEmbeddedDutchiePayLink,
}: {
  handleEmbeddedDutchiePayLink?: () => void;
}): JSX.Element => {
  const { User } = useStores();
  const userFullName = useObserver(() => User.fullName);
  const { trackPlaidOnEvent } = useDutchiePayAnalytics();

  const { plaidLinkExited } = useDutchiePayEnrollment();

  const userEmail = useObserver(() => User.email);
  const userPhone = useObserver(() => User.profile.phone);

  const { handleSuccess } = usePlaidLinkUtils({ handleEmbeddedDutchiePayLink });

  const { data: startAggregatorLinkData } = useStartAggregatorLinkQuery({
    variables: {
      aggregator: AGGREGATORS.PLAID,
      redirectUrl: REDIRECT_URL,
      entityName: userFullName,
      paymentMethodId: '',
      email: userEmail,
      phone: userPhone,
    },
    skip: !userFullName,
    fetchPolicy: 'network-only',
  });

  const plaidLinkToken = startAggregatorLinkData?.startAggregatorLink.plaid.linkToken ?? '';

  const clearPlaidData = (): void => {
    localStorage.removeItem(DUTCHIE_PAY_QUERY_PARMAS_KEY);
    localStorage.removeItem(PLAID_STORAGE_KEY);
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken, metadata) => {
      void handleSuccess({ publicToken, metadata });
    },
    [handleSuccess]
  );

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent, metadata: PlaidLinkOnEventMetadata) => {
      trackPlaidOnEvent(eventName, metadata);
    },
    [trackPlaidOnEvent]
  );

  const onExit = useCallback<PlaidLinkOnExit>(() => {
    clearPlaidData();
    plaidLinkExited();
  }, [plaidLinkExited]);

  const config = {
    token: plaidLinkToken,
    onSuccess,
    onEvent,
    onExit,
  };

  return (
    <PlaidEmbeddedLink
      {...config}
      style={{
        height: '350px',
      }}
    />
  );
};

export const MemoizedPlaidEmbeddedSearch = React.memo(PlaidEmbeddedSearch);
