// These enums describe all the different files needed for favicons under a
// variety of scenarios - like browser, share sheet, home screen, etc.

// You can generate these icon sets using https://realfavicongenerator.net/

export enum FaviconAppleTouchIcon {
  default = '/favicons/default/apple-touch-icon.png',
  plus = '/favicons/plus/apple-touch-icon.png',
}

export enum Favicon16 {
  default = '/favicons/default/favicon-16x16.png',
  plus = '/favicons/plus/favicon-16x16.png',
}

export enum Favicon32 {
  default = '/favicons/default/favicon-32x32.png',
  plus = '/favicons/plus/favicon-32x32.png',
}

export enum FaviconManifest {
  default = '/favicons/default/site.webmanifest',
  plus = '/favicons/plus/site.webmanifest',
}

export enum FaviconSafariPinnedTab {
  default = '/favicons/default/safari-pinned-tab.svg',
  plus = '/favicons/plus/safari-pinned-tab.svg',
}

export enum FaviconSafariPinnedTabColor {
  default = '#ff3e51',
  plus = '#8298FC',
}

export enum Favicon {
  default = '/favicons/default/favicon.ico',
  plus = '/favicons/plus/favicon.ico',
}

export enum FaviconMsTileColor {
  default = '#ff3e51',
  plus = '#0c1829',
}

export enum FaviconMsBrowserConfig {
  default = '/favicons/default/browserconfig.xml',
  plus = '/favicons/plus/browserconfig.xml',
}

export enum FaviconThemeColor {
  default = '#ffffff',
}

export enum FaviconMetaImage {
  plus = '/favicons/plus/meta-image.png',
}
