import React, { forwardRef } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useTranslation from 'hooks/use-translation';
import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import useErnie from 'shared/hooks/use-ernie';
import { InterfaceVariants } from 'src/constants/interface-variants';

import { ModalContainer, Button, BottomDrawer } from 'components/core';
import { ModalContent } from 'components/modals/components';

const ModalChildren = ({ onClose }) => {
  const { t, Trans } = useTranslation();
  const Cart = useCart();
  const showErnie = useErnie();

  const handleYes = () => {
    Cart.address.residentialStatus = 'VERIFIED';
    showErnie(
      t(
        'residentialVerificationModal.success',
        'Success! The address you entered is within the delivery area for this dispensary!'
      ),
      `success`
    );

    onClose(true);
  };

  const handleNo = () => {
    Cart.address.residentialStatus = 'UNVERIFIED';
    showErnie(t('residentialVerificationModal.decline', 'Please enter a valid residential address.'), `warning`);
    onClose(false);
  };
  return (
    <StyledModalContent>
      <Heading>{t('common.warning', 'Warning!')}</Heading>
      <WarningVerbiage>
        <Trans i18nKey='residentialVerification.isResidential'>
          {/* eslint-disable-next-line max-len */}
          Any delivery to a hotel, business, or dorm will be cancelled. Under state regulations, deliveries can only be
          made to a residence.<b>&nbsp;Is this address a residence?</b>
        </Trans>
      </WarningVerbiage>
      <ButtonContainer>
        <StyledButton onClick={handleYes} autoFocus>
          {t('common.yesResidence', 'Yes, this is a residence')}
        </StyledButton>
        <StyledLink variant='secondary' onClick={handleNo}>
          {t('common.notResidence', 'No, this is not a residence')}
        </StyledLink>
      </ButtonContainer>
    </StyledModalContent>
  );
};

const ResidentialVerificationModal = forwardRef(({ containerProps, onClose }, ref) => {
  const UI = useUI();
  const isDesktopMenuSize = useMediaQuery((theme) => theme.breakpoints.up(`md`));
  const isEmbedded = UI.variant === InterfaceVariants.embedded;

  if (isEmbedded || isDesktopMenuSize) {
    return (
      <ModalContainer
        ref={ref}
        height='auto'
        width={459}
        {...containerProps}
        data-testid='residential-verification-modal'
      >
        <ModalChildren onClose={onClose} />
      </ModalContainer>
    );
  }

  return (
    <BottomDrawer
      open={UI.showResidentialVerificationModal.open}
      onClose={onClose}
      hideExitIcon
      isBackgroundVisible
      data-testid='residential-verification-modal'
    >
      <ModalChildren onClose={onClose} />
    </BottomDrawer>
  );
});

export default ResidentialVerificationModal;

const StyledModalContent = styled(ModalContent)`
  padding-top: 7px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    &&& {
      padding: 0px;
    }
  }
`;

const Heading = styled.h2`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 21px;
`;

const WarningVerbiage = styled.span`
  font-size: 13px;
  line-height: 21.45px;
  max-width: 345px;
  color: ${({ theme }) => theme.colors?.grey[30]};
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  margin: 20px 0 15px 0;
  height: 48px;
  width: 100%;
`;

const StyledLink = styled.button`
  appearance: none;
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 13px;
  cursor: pointer;
  ${({ theme }) => `color: ${theme.customized.colors.buttonsLinks};`}
  display: block;
  margin: auto;
`;
