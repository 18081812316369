import React from 'react';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Header } from 'src/dispensary/core-menu/components/header';
import CoreMenuHeader from 'dispensary/core-menu/components/core-menu-header';
import CoreMenuFooter from 'dispensary/core-menu/components/core-menu-footer';
import ROUTES from 'src/routes';
import { DesktopOnly } from 'shared/components';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';
import useUser from 'hooks/use-user';
import useFeatureFlags from 'hooks/use-feature-flags';

import { useCheckAgeVerification } from 'src/dispensary/core-menu/age-verification-gate/hooks';

import { LayoutWrapper } from './layout-wrapper';
import { AgeVerificationGateLayout } from './age-verification-gate-layout';

type CoreMenuLayoutProps = {
  children: React.ReactNode;
};

export const CoreMenuLayout = observer(({ children }: CoreMenuLayoutProps): JSX.Element | null => {
  const { dispensary } = useDispensary();
  const FeatureFlags = useFeatureFlags();
  const { productCount } = useUI();
  const router = useRouter();
  const { route } = usePaths();
  const user = useUser();
  const { isAgeVerified, loading } = useCheckAgeVerification({
    dispensary,
    userId: user?.id,
  });
  const flags = useFlags();
  const isNewEmbeddedHeaderFlagEnabled = flags['growth.ecomm.embedded-header.rollout'] ?? false;

  if (!dispensary || !FeatureFlags.hasTargetDispensary) {
    return null;
  }

  if (!loading && !isAgeVerified) {
    return <AgeVerificationGateLayout children={children} />;
  }

  const isProductPage = router.route === `${route}${ROUTES.PRODUCT_ID}`;
  // hide cat tiles unless we are on the category, subcategory, product search, and brand(s) pages
  const hideCategoryTiles = !(router.route.includes('products') || router.route.includes('brands'));
  const hideCategoryTilesHeader = !productCount;

  const OldHeaderComponent = isProductPage ? (
    <DesktopOnly display='flex'>
      <CoreMenuHeader dispensary={dispensary} />
    </DesktopOnly>
  ) : (
    <CoreMenuHeader dispensary={dispensary} />
  );

  return (
    <LayoutWrapper>
      {isNewEmbeddedHeaderFlagEnabled ? <Header dispensary={dispensary} /> : OldHeaderComponent}

      {children}

      <CoreMenuFooter hideCategories={hideCategoryTiles} hideHeader={hideCategoryTilesHeader} />
    </LayoutWrapper>
  );
});
