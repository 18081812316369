/* eslint-disable @typescript-eslint/naming-convention,camelcase */
import _isNil from 'lodash/isNil';
import { AnalyticsContext, AnalyticsOrder } from '../../events';

export const getItemProps = ({ product }: any): Record<string, unknown> => ({
  id: product.id,
  library_product_id: product.libraryProductId,
  library_product_score: product.libraryProductScore,
  brand_id: product.brandId,
  parent_brand_id: product.brand?.parentBrandId,
});

export const getCartItemProps = ({ product, quantity, option, basePrice, price }: any): Record<string, unknown> => ({
  ...getItemProps({ product }),
  price: basePrice ?? price ?? product?.Prices?.[0],
  quantity,
  option,
});

export const getPlacedOrderCartItemProps = ({
  product,
  quantity,
  option,
  basePrice,
  price,
  discountedBasePrice,
}: any): Record<string, unknown> => ({
  ...getItemProps({ product }),
  ...getCartItemProps({ product, quantity, option, basePrice, price }),
  base_price: basePrice,
  discounted_base_price: discountedBasePrice,
  total_price: price,
});

export const getCartTotalValue = (context: AnalyticsContext): string | undefined =>
  context.activeCart?.subtotal?.toFixed(2);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getCommonProps = ({ activeDispensary, activeMenuVariant }: AnalyticsContext) => ({
  dispensary_id: activeDispensary?.id,
  menu_variant: activeMenuVariant,
});

export const getOrderProps = (order: AnalyticsOrder): Record<string, unknown> => ({
  subtotal: getOrderReceiptSummaryValue(order, 'subtotal'),
  discount: getOrderReceiptSummaryValue(order, 'discounts'),
  final_total: getOrderReceiptSummaryValue(order, 'total'),
  order_id: order.id,
});

export const getOrderReceiptSummaryValue = ({ receipt }: any, key: string): string | undefined => {
  const value = receipt?.summary?.[key];

  console.log('value', value);

  if (_isNil(value)) {
    return undefined;
  }

  return (value / 100).toFixed(2);
};
