/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { BankIconOutline } from 'assets/bank-icon-outline';
import { PoweredByPlaid } from 'src/payments/components';
import { Button } from 'components/core';
import { ModalNames, useModals } from 'components/modals';
import {
  ENROLLMENT_EVENTS,
  useDutchiePayAnalytics,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { useOpenDutchiePay } from 'src/payments/hooks/use-open-dutchie-pay/use-open-dutchie-pay';
import { useDutchiePay } from 'src/payments/hooks';
import useUI from 'src/hooks/use-ui';
import { formatPaymentsError } from 'src/payments/hooks/use-payments-error/use-payments-error';
import useErnie from 'shared/hooks/use-ernie';
import { DutchiePayLogo as _DutchiePayLogo } from 'assets/dutchie-pay-logo';
import InfoIconFilled from 'src/assets/info-icon-filled';
import { getColors } from 'src/theme';
import { WhiteLabelLogo } from '../white-label-logo';

const themeColors = getColors();

export type PayButtonPosition = 'bottom' | 'top';

export type PaySignUpButtonProps = {
  textPosition?: PayButtonPosition;
  cname?: string;
  goToCheckout?: () => void;
  refHref?: string;
  isMobile?: boolean;
  enrollmentSource?: string;
  location: 'cart-rail' | 'customer-section' | 'payment-section';
};

const LearnMoreButton = (): JSX.Element => {
  const { openModal } = useModals();
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();

  const handleOpenDutchiePayCtaModal = useCallback(async (): Promise<boolean> => {
    dutchiePayEventTracker(ENROLLMENT_EVENTS.CTA_BUTTON_CLICKED);

    return openModal(ModalNames.dutchiePayCta);
  }, [dutchiePayEventTracker, openModal]);

  return (
    <LearnMore onClick={handleOpenDutchiePayCtaModal}>
      <InfoIconFilled width={16} height={16} color={themeColors.grey[70]} />
    </LearnMore>
  );
};

export const PaySignUpButton = ({
  textPosition = `bottom`,
  cname,
  goToCheckout,
  refHref,
  isMobile,
  enrollmentSource,
  location,
}: PaySignUpButtonProps): JSX.Element => {
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();
  const { isEnrolledDutchiePay, whiteLabelConfig, error } = useDutchiePay();
  const { isMobileEcommApp } = useUI();
  const { openDutchiePay } = useOpenDutchiePay({ cname: cname ?? '', refHref, enrollmentSource });
  const { openModal } = useModals();
  const showErnie = useErnie();

  const handleClick = (): void => {
    if (error) {
      const { errorMarkdown, ernieType, ernieTimeout } = formatPaymentsError(
        error,
        whiteLabelConfig.brandedDutchiePayName
      );
      showErnie(errorMarkdown, ernieType, ernieTimeout);
      return;
    }

    if (isMobileEcommApp) {
      void openModal(ModalNames.dutchiePayVisitOnDesktop);
    } else if (goToCheckout) {
      goToCheckout();
    } else {
      openDutchiePay();
      dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_BUTTON_CLICKED, { buttonLocation: location });
    }
  };

  return (
    <>
      <DutchiePayButton
        data-testid='dutchie-pay-signup-button'
        onClick={handleClick}
        aria-label='dutchiePay'
        isMobile={isMobile}
        custom={whiteLabelConfig.useWhiteLabel}
      >
        <BankIconOutline />
        <span>Pay by bank instantly</span>
      </DutchiePayButton>
      {!isEnrolledDutchiePay && (
        <Subtext textPosition={textPosition} isMobile={isMobile}>
          {whiteLabelConfig.useWhiteLabel ? (
            <>
              <CustomLogoWrapper>
                <WhiteLabelLogo variant='dark' height='14' />
              </CustomLogoWrapper>
              <PoweredByPlaid />
              <LearnMoreButton />
            </>
          ) : (
            <>
              <DutchiePayLogo width='75' height='14' />
              <PoweredByPlaid />
              <LearnMoreButton />
            </>
          )}
        </Subtext>
      )}
    </>
  );
};

const DutchiePayButton = styled(Button)`
  ${({ theme: { breakpoints, colors, spaces }, custom }) => css`
    --font-family: ${custom ? `inherit` : `Matter, proxima-nova, sans-serif`};
    --font: normal 22px/27px var(--font-family);

    align-items: center;
    background-color: ${custom ? colors.customLinkColor : colors.dutchiePayGreen};
    border: none;
    color: ${colors.white};
    cursor: pointer;
    display: flex;
    font: var(--font);
    height: 45px;
    justify-content: center;
    text-transform: none;
    width: 100%;

    ${breakpoints.down('xs')} {
      font-size: 16px;
      height: 43px;
    }

    &&& {
      padding: 10px 15px;

      span {
        margin-right: 5px;
      }

      svg {
        margin-right: ${spaces[4]};
        vertical-align: baseline;
      }
    }
  `}
`;

const DutchiePayLogo = styled(_DutchiePayLogo)`
  ${({ theme: { colors } }) => css`
    color: ${colors.dutchiePayGreen};
  `}
`;

const Subtext = styled.div`
  ${({ theme: { breakpoints, colors, spaces } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spaces[2]};
    color: ${colors.grey[45]};
    font-size: 12px;
    margin-top: ${spaces[3]};

    ${breakpoints.down('xs')} {
      flex-direction: column;
    }
  `}
`;

const LearnMore = styled.button`
  ${({ theme: { customized } }) => css`
    background: transparent;
    color: inherit;
    text-decoration: underline;
    border: none;
    padding: 0;
    cursor: pointer;
    line-height: 0;

    &:hover {
      color: ${customized.colors.buttonsLinks};
    }
  `}
`;

const CustomLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 14px;
`;
