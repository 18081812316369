/* eslint-disable camelcase */
import React from 'react';

import useTranslation from 'hooks/use-translation';
import useLogRocket from 'src/hooks/use-logrocket';
import { useOpenDutchiePay } from 'src/payments/hooks/use-open-dutchie-pay/use-open-dutchie-pay';
import useUI from 'src/hooks/use-ui';

import {
  useDutchiePayAnalytics,
  ENROLLMENT_EVENTS,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';

import { GqlOrders_Dispensary } from 'types/graphql';

import {
  Button,
  Container,
  ContentContainer,
  DesktopImage,
  DesktopLogoText,
  ImageContainer,
  MobileImage,
  MobileLogoText,
  StyledBody,
  StyledDutchiePayLogo,
  StyledHeading,
  TextContainer,
} from './checkout-success-cta.styles';

type DutchiePayCheckoutSuccessCTAProps = {
  dispensary: GqlOrders_Dispensary;
  isGuestOrder?: boolean;
  onClose?: () => void;
};

export default function DutchiePayCheckoutSuccessCTA({
  dispensary,
  onClose,
  isGuestOrder = false,
}: DutchiePayCheckoutSuccessCTAProps): JSX.Element {
  const { Trans, t } = useTranslation();
  const { track } = useLogRocket();
  const { variant } = useUI();

  const { dutchiePayEventTracker } = useDutchiePayAnalytics();
  const { openDutchiePay } = useOpenDutchiePay({
    cname: String(dispensary.cName),
    onCloseModal: onClose,
    enrollmentSource: `order-confirmation-${isGuestOrder ? `guest` : `logged-in`}-${String(variant)}`,
  });

  const desktopImageSrc = isGuestOrder
    ? `https://images.dutchie.com/pay/dutchie-pay-cta-checkout-modal-sidebar.jpg`
    : `https://images.dutchie.com/pay/dutchie-pay-cta-checkout-modal-horizontal.jpg`;

  const onCtaClick = (): void => {
    // TODO: Setup LogRocket in the FE Analytics framework https://dutchie.atlassian.net/browse/ENG-31004
    // TODO: Come back in and set up individual tracking events for logged-in vs. guest user clicks
    dutchiePayEventTracker(ENROLLMENT_EVENTS.CTA_BUTTON_CLICKED);
    openDutchiePay();
    // TODO: Setup LogRocket in the FE Analytics framework https://dutchie.atlassian.net/browse/ENG-31004
    track(`DutchiePay ${isGuestOrder ? `Guest Checkout` : `Checkout`} Success CTA Button Clicked`);
  };

  return (
    <Container vertical={isGuestOrder}>
      <ImageContainer>
        <DesktopImage
          fit='fill'
          htmlAttributes={{ alt: t('common.modals.dutchie-pay-logo-use', 'Image of Dutchie Pay in use') }}
          src={desktopImageSrc}
        />
        <MobileImage
          htmlAttributes={{ alt: t('common.modals.dutchie-pay-logo-use', 'Image of Dutchie Pay in use') }}
          src='https://images.dutchie.com/pay/dutchie-pay-cta-checkout-modal-mobile.jpg'
        />
      </ImageContainer>
      <ContentContainer vertical={isGuestOrder}>
        <TextContainer>
          <StyledDutchiePayLogo vertical={isGuestOrder} />
          <Trans i18nKey='dutchiePay.nextTime'>
            <StyledHeading size='small' tag='h3' vertical={isGuestOrder}>
              Next time, speed through checkout with{' '}
              <MobileLogoText vertical={isGuestOrder}>Dutchie Pay.</MobileLogoText>
              <DesktopLogoText vertical={isGuestOrder}>
                <strong>dutchie</strong>Pay
              </DesktopLogoText>
            </StyledHeading>
          </Trans>

          <StyledBody size='small' vertical={isGuestOrder}>
            {t('dutchiePay.securePayments', 'Secure, cashless payments for cannabis.')}
          </StyledBody>
        </TextContainer>
        <Button onClick={onCtaClick} vertical={isGuestOrder}>
          {t('common.signup', 'Sign Up')}
        </Button>
      </ContentContainer>
    </Container>
  );
}
