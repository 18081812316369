export const PRODUCT_GROUP_SIZES = {
  grid: {
    md: {
      breakpoint: 960,
      rowSize: 3,
      adPosition: 6,
    },
    lg: {
      breakpoint: 1045,
      rowSize: 4,
      adPosition: 8,
    },
    xl: {
      breakpoint: 1240,
      rowSize: 5,
      adPosition: 10,
    },
  },
};

export const PRODUCT_GRID_TEST_ID = 'product-grid';
