import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'src/typography';
import { Button } from 'components/core';
import useTranslation from 'hooks/use-translation';
import useStores from 'shared/hooks/use-stores';

export const KioskQrCode = (): JSX.Element => {
  const { t } = useTranslation();
  const { Cart, UI } = useStores();

  // This is temp for now, just a way to simulate the pusher call
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timerId = setTimeout(() => {
      UI.updateShowDutchiePayKioskOrderSuccess(true);
    }, 8000);
    return () => clearTimeout(timerId);
  }, [UI]);

  const cartItemsLength: number = Cart?.itemCount ?? 0;
  const menuTotalAmount: number = Cart.costBreakdown.menuTotal;

  return (
    <ModalBody>
      <Content>
        <ItemsText size='small'>{`${cartItemsLength.toString()} ${
          cartItemsLength === 1 ? 'ITEM' : 'ITEMS'
        }`}</ItemsText>
        <Heading size='medium'>{`Total: $${menuTotalAmount}`}</Heading>
        <QrCodeContainer>
          <img
            src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png'
            width={200}
            alt='qr-code'
          />
        </QrCodeContainer>
        <ScanText size='small'> {t('dutchiePay.scan', 'Scan to pay with Dutchie Pay')}</ScanText>
        <SloganText size='small'>{t('dutchiePay.secure', 'Secure, fast, cashless payments for cannabis.')}</SloganText>
        <GoBackButton onClick={() => UI.updateShowDutchiePayQrCodeInKiosk(false)}>
          {t('common.goBack', 'Go Back')}
        </GoBackButton>
      </Content>
    </ModalBody>
  );
};

const ModalBody = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.grey[95]};
`;

const Content = styled.div`
  ${({ theme: { spaces } }) => css`
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${spaces[2]};
  `}
`;

const QrCodeContainer = styled.div`
  ${({ theme: { spaces, colors } }) => css`
    background: ${colors.white};
    border-radius: ${spaces[5]};
    padding: ${spaces[8]};
    margin: ${spaces[5]} 0;
    img {
      border: 10px solid ${({ theme }) => theme.colors.black};
      border-radius: ${spaces[5]};
    }
  `}
`;

const GoBackButton = styled(Button)`
  ${({ theme: { spaces, colors } }) => css`
    width: 100%;
    background: transparent;
    font-size: ${spaces[4]};
    color: ${colors.grey[35]};
    border: 2px solid ${colors.grey[35]};
    padding: 14px 0;
    margin-top: ${spaces[4]};
    cursor: pointer;
  `}
`;

const ItemsText = styled(Typography.Body)`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[50]};
    font-weight: 600;
  `}
`;

const Heading = styled(Typography.Heading)`
  ${({ theme: { colors } }) => css`
    color: ${colors.primaryBlack};
  `}
`;

const ScanText = styled(Typography.Heading)`
  ${({ theme: { colors } }) => css`
    color: ${colors.primaryBlack};
  `}
`;

const SloganText = styled(Typography.Body)`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[50]};
  `}
`;
