import { RadiusOption, TextTransformOption } from 'types/graphql';

export const textTransforms = [
  {
    label: 'UPPERCASE',
    value: TextTransformOption.upper,
    style: 'uppercase',
  },
  {
    label: 'Title Case',
    value: TextTransformOption.title,
    style: 'capitalize',
  },
  {
    label: 'Sentence case',
    value: TextTransformOption.sentence,
    style: 'none',
  },
  {
    label: 'lowercase',
    value: TextTransformOption.lower,
    style: 'lowercase',
  },
];

export const radius = [
  {
    label: 'Rounded',
    value: RadiusOption.rounded,
    style: {
      sm: '4px',
      md: '8px',
      lg: '12px',
      xl: '16px',
      tags: '30px',
      cards: '16px',
      buttons: '9999px',
    },
  },
  {
    label: 'Rounded Rectangle',
    value: RadiusOption.roundedRectangle,
    style: {
      sm: '4px',
      md: '8px',
      lg: '12px',
      xl: '16px',
      tags: '4px',
      cards: '12px',
      buttons: `8px`,
    },
  },
  {
    label: 'Square',
    value: RadiusOption.square,
    style: {
      sm: '0',
      md: '0',
      lg: '0',
      xl: '0',
      tags: '0',
      cards: '0',
      buttons: '0',
    },
  },
];
