import React from 'react';
import { useIdleTimerContext } from 'react-idle-timer';
import { useRouter } from 'next/router';

import useCart from 'src/hooks/use-cart';
import useUI from 'src/hooks/use-ui';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { DesktopOnly, MobileOnly } from 'shared/components';

import { ModalContainer, BottomDrawer } from 'src/components/core';
import { withModalControlsHook, ModalComponentProps } from 'src/components/modals/with-modal-controls-hook';
import { IdleWarning } from './idle-warning';

const IdleWarningModal = ({ onClose }: ModalComponentProps): JSX.Element => {
  const { clearOrder } = useCart();
  const UI = useUI();
  const router = useRouter();
  const idleTimer = useIdleTimerContext();
  const dispoCname = router.query.cName;

  const closeAndContinue = (): void => {
    onClose();
    idleTimer.reset();
  };

  const startNewSession = (): void => {
    UI.showKioskCheckoutModal = false;
    clearOrder();
    onClose();
    idleTimer.reset();
    void router.push(
      `/${String(UI.dispensaryRootUrl)}/[cName]/lock`,
      `/${String(UI.dispensaryRootUrl)}/${String(dispoCname)}/lock`
    );
  };

  return (
    <>
      <DesktopOnly mediaQuery='sm'>
        <ModalWrapper open>
          <ModalContainer width='470px' p='32px'>
            <IdleWarning closeAndContinue={closeAndContinue} startNewSession={startNewSession} />
          </ModalContainer>
        </ModalWrapper>
      </DesktopOnly>
      <MobileOnly mediaQuery='xs'>
        <BottomDrawer open onClose={closeAndContinue}>
          <IdleWarning closeAndContinue={closeAndContinue} startNewSession={startNewSession} />
        </BottomDrawer>
      </MobileOnly>
    </>
  );
};

const [IdleWarningController, useIdleWarningModal] = withModalControlsHook(IdleWarningModal);

export { useIdleWarningModal, IdleWarningController };
