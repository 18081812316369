import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { useConsentManagement } from 'hooks/use-consent-management';
import GlobalHeader from 'components/global-header';
import GlobalFooter from 'components/global-footer-rebrand';
import { DutchiePrivacyFooter } from 'src/dispensary/core-menu/components/dutchie-privacy-footer';

type LayoutWithHeaderProps = {
  children: React.ReactNode;
  hideFooter?: boolean;
};

const LayoutWithHeader = forwardRef<HTMLDivElement, LayoutWithHeaderProps>((props, ref) => {
  const { hideFooter, children, ...otherProps } = props;
  const { isConsentManagementEnabled } = useConsentManagement();

  return (
    <Container ref={ref}>
      <GlobalHeader />
      <Content {...otherProps}>{children}</Content>
      {isConsentManagementEnabled && <DutchiePrivacyFooter />}
      {!hideFooter && <GlobalFooter />}
    </Container>
  );
});

export default LayoutWithHeader;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Content = styled.div`
  flex: 1 0 0%;
`;
