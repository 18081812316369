// These are the filter options available in the sidebar "Filters" section
// on the /dispensaries page. Depending on order type (pickup/delivery)
// there are different options available.
export const DISPENSARIES_FILTER_OPTIONS = {
  pickup: [
    {
      key: 'openNow',
      label: 'Open Now',
      labelTranslationKey: 'dispensaryFilters.openNow',
      value: 'openNow',
    },
    {
      key: 'offerCurbsidePickup',
      label: 'Curbside Pickup',
      labelTranslationKey: 'dispensaryFilters.offerCurbsidePickup',
      value: 'offerCurbsidePickup',
    },
    {
      key: 'acceptsCreditCards',
      label: 'Accepts Credit Cards',
      labelTranslationKey: 'dispensaryFilters.acceptsCreditCards',
      value: 'acceptsCreditCards',
    },
    {
      key: 'acceptsDutchiePay',
      label: 'Accepts Dutchie Pay',
      labelTranslationKey: 'dispensaryFilters.acceptsDutchiePay',
      value: 'acceptsDutchiePay',
    },
  ],
  delivery: [
    {
      key: 'openNow',
      label: 'Open Now',
      labelTranslationKey: 'dispensaryFilters.openNow',
      value: 'openNow',
    },
    {
      key: 'freeDelivery',
      label: 'Free Delivery',
      labelTranslationKey: 'dispensaryFilters.freeDelivery',
      value: 'freeDelivery',
    },
    {
      key: 'noMinimum',
      label: 'No Delivery Min.',
      labelTranslationKey: 'dispensaryFilters.noMinimum',
      value: 'noMinimum',
    },
    {
      key: 'acceptsCreditCards',
      label: 'Accepts Credit Cards',
      labelTranslationKey: 'dispensaryFilters.acceptsCreditCards',
      value: 'acceptsCreditCards',
    },
    {
      key: 'acceptsDutchiePay',
      label: 'Accepts Dutchie Pay',
      labelTranslationKey: 'dispensaryFilters.acceptsDutchiePay',
      value: 'acceptsDutchiePay',
    },
  ],
};

export const PICKUP_FILTER_OPTION_KEYS = [`openNow`, `offerCurbsidePickup`, `acceptsCreditCards`, `acceptsDutchiePay`];

export const DELIVERY_FILTER_OPTION_KEYS = [
  `openNow`,
  `freeDelivery`,
  `noMinimum`,
  `acceptsCreditCards`,
  `acceptsDutchiePay`,
];

export const STORE_TYPE_REC = 'recreational';
export const STORE_TYPE_MED = 'medical';

export const STORE_TYPE_OPTIONS = [
  {
    label: 'Recreational',
    key: STORE_TYPE_REC,
    value: STORE_TYPE_REC,
  },
  {
    label: 'Medical',
    key: STORE_TYPE_MED,
    value: STORE_TYPE_MED,
  },
];

export const DELIVERY_SORT_OPTIONS = [
  {
    key: 'deliveryEstimate',
    value: 'deliveryEstimate',
    label: 'Delivery Time',
  },
  {
    key: 'deliveryFee',
    value: 'deliveryFee',
    label: 'Delivery Fee',
  },
  {
    key: 'deliveryMin',
    value: 'deliveryMin',
    label: 'Delivery Minimum',
  },
];

export const PICKUP_SORT_OPTIONS = [
  {
    key: 'distance',
    value: 'distance',
    label: 'Distance',
  },
  {
    key: 'pickupTime',
    value: 'pickupTime',
    label: 'Pickup Time',
  },
];
