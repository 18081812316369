import styled from 'styled-components';

import { DutchiePayLogo } from 'assets/dutchie-pay-logo';
import { ModalContainer, ModalExitIcon } from 'shared/modals/v2-container';
import { DutchiePayButton, WhiteLabelLogo } from 'src/payments/components';
import Imgix from 'shared/components/imgix';
import { Typography } from 'src/typography';

export const StyledModalContainer = styled(ModalContainer)`
  background: none;
  overflow: hidden;
  width: 378px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 778px;
  }
`;

export const StyledModalExitIcon = styled(ModalExitIcon)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    fill: #fff;
  }
`;

export const ModalWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
  }
`;

export const ImageContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: relative;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-shrink: 0;
    width: 298px;
  }
`;

export const MobileImage = styled(Imgix)`
  display: block;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const DesktopImage = styled(Imgix)`
  height: 100%;
  object-fit: cover;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const ModalContent = styled.div`
  background: #fff;
  padding: 32px;
  text-align: left;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 48px;
  }
`;

export const MobileDutchiePayLogo = styled(DutchiePayLogo)`
  color: #fff;
  left: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const MobileWhiteLabelLogo = styled(WhiteLabelLogo).attrs({
  variant: `dark`,
})`
  left: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const DesktopDutchiePayLogo = styled(DutchiePayLogo)`
  color: ${({ theme }) => theme.colors.dutchiePayGreen};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const DesktopWhiteLabelLogo = styled(WhiteLabelLogo).attrs({
  variant: `dark`,
})`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const StyledHeading = styled(Typography.Heading)`
  margin: 0 0 12px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 20px;
  }
`;

export const StyledIntro = styled(Typography.Body)`
  color: #464f53;
`;

export const StyledBody = styled(Typography.Body)`
  color: #464f53;
  margin-bottom: 16px;
`;

export const StyledTitle = styled(Typography.Title)`
  color: #464f53;
  margin: 32px 0 14px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 52px;
  }
`;

export const StyledList = styled.ul`
  color: #464f53;
  list-style: none;
  margin: 0 0 36px;
  padding: 0;
`;

export const StyledListItem = styled(Typography.Body)`
  align-items: center;
  display: flex;
  margin: 0 0 12px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }

  svg {
    margin-right: 8px;
  }

  path {
    stroke: currentColor;
  }
`;

export const Button = styled(DutchiePayButton)`
  font-size: 15px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: auto;
  }
`;

export const StyledButtonText = styled.span`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;
