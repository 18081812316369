export var PusherConfig = {
  Dispensary: {
    CHANNEL: function CHANNEL(dispensaryId) {
      return "private-curbside-arrivals-user-".concat(dispensaryId);
    },
    Events: {
      CURBSIDE_ARRIVAL_ARRIVED: 'curbside-arrival-arrived',
      CURBSIDE_ARRIVAL_CLEARED_ALL: 'curbside-arrival-cleared-all',
      CURBSIDE_ARRIVAL_CLEARED: 'curbside-arrival-cleared',
      CURBSIDE_ARRIVAL_UPDATED: 'curbside-arrival-updated'
    }
  },
  DispensaryOrders: {
    CHANNEL: function CHANNEL(_ref) {
      var id = _ref.id;
      return "dispensary-".concat(id, "-orders");
    },
    Events: {
      ORDER_UPDATED: 'order-updated'
    }
  },
  Order: {
    CHANNEL: function CHANNEL(orderId) {
      return "order-".concat(orderId);
    },
    Events: {
      ORDER_UPDATED: 'order-updated'
    }
  },
  PrivateOrder: {
    CHANNEL: function CHANNEL(orderId) {
      return "private-order-".concat(orderId);
    },
    Events: {
      ORDER_PRINTED: 'client-order-printed'
    }
  },
  PersistentRequests: {
    CHANNEL: function CHANNEL(_ref2) {
      var id = _ref2.id;
      return "persistent-requests-session-".concat(id);
    },
    Events: {
      RESOLVED: 'persistent-request-resolved'
    }
  },
  Terminal: {
    CHANNEL: function CHANNEL(_ref3) {
      var deviceId = _ref3.deviceId;
      return "private-terminal-".concat(deviceId);
    },
    Events: {
      PRINT: 'print-from-commands'
    }
  },
  Consumer: {
    CHANNEL: 'consumer-general',
    Events: {
      ReloadApp: 'reload-app'
    }
  },
  Admin: {
    CHANNEL: 'admin-general',
    Events: {
      ReloadApp: 'reload-app'
    }
  },
  ContentCleanup: {
    CHANNEL: 'presence-content-cleanup-tool',
    Events: {
      SUBSCRIPTION_ERROR: 'pusher:subscription_error',
      SUBSCRIPTION_SUCCEEDED: 'pusher:subscription_succeeded'
    }
  },
  Buckets: {
    CHANNEL: 'buckets',
    Events: {
      CREATED: 'created',
      DESTROYED: 'destroyed',
      UPDATED: 'updated'
    }
  },
  DeviceConnections: {
    Events: {
      sendConnectionToken: 'SEND_CONNECTION_TOKEN',
      connectionFinalized: 'CONNECTION_FINALIZED'
    }
  }
};