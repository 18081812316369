import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useObserver } from 'mobx-react';
import { useRouter } from 'next/router';

import { Button as _Button } from 'components/core';
import { Typography } from 'src/typography';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';

import { connectingBankSteps, EnrollmentSteps, PLAID_TERMS_URL } from 'src/payments/constants';
import { useDutchiePayAnalytics } from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { useActivatePaymentMethod } from 'src/payments/dutchie-pay/signup/views/terms/use-activate-payment-method';
import { MemoizedPlaidEmbeddedSearch } from 'src/payments/components';
import { Loading } from 'src/payments/dutchie-pay/signup/views';
import { useRecordUserAcceptance } from './use-record-user-acceptance';

export function TermsWithSearch(): JSX.Element {
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { trackEnrollmentStepSubmitted } = useDutchiePayAnalytics();
  const { recordUserAcceptance } = useRecordUserAcceptance();
  const { activatePaymentMethod } = useActivatePaymentMethod();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const connectedBankAccountDetails = useObserver(() => DutchiePayEnrollment.connectedBankAccountDetails);
  const aggregatorLinkLoading = useObserver(() => DutchiePayEnrollment.aggregatorLinkLoading);
  const aggregatorLinkCompleted = useObserver(() => DutchiePayEnrollment.aggregatorLinkCompleted);

  const onAcceptTermsClick = async (): Promise<void> => {
    setIsLoading(true);
    trackEnrollmentStepSubmitted(EnrollmentSteps.terms);

    const userAcceptance = await recordUserAcceptance();
    if (userAcceptance) {
      await activatePaymentMethod();
    } else {
      DutchiePayEnrollment.cancelEnrollment();
      void router.push(DutchiePayEnrollment.refUrl);
    }
    setIsLoading(false);
  };

  return (
    <>
      <MemoizedPlaidEmbeddedSearch />
      <TermsWrapper>
        <Controls>
          {aggregatorLinkLoading && <Loading steps={connectingBankSteps} />}
          {aggregatorLinkCompleted && connectedBankAccountDetails && (
            <>
              <Typography.Body size='small'>
                By clicking "Continue to cart" I agree to the{' '}
                <A href={PLAID_TERMS_URL} target='_blank'>
                  terms
                </A>{' '}
                and I authorize electronic debits from my account for purchases made using Dutchie Pay. I also agree to
                the{' '}
                <A href={PLAID_TERMS_URL} target='_blank'>
                  terms
                </A>{' '}
                related to the use of Electronic Records and Signatures.
              </Typography.Body>
              <Button
                type='button'
                size='large'
                onClick={onAcceptTermsClick}
                disabled={!connectedBankAccountDetails}
                loading={isLoading}
                variant='dutchiePay'
              >
                Continue to cart
              </Button>
            </>
          )}
        </Controls>
      </TermsWrapper>
    </>
  );
}

const TermsWrapper = styled.div`
  position: relative;
  margin-top: 20px;
`;

const A = styled.a`
  ${({ theme: { customized } }) => css`
    color: ${customized.colors.buttonsLinks};
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  `};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Controls = styled.div`
  ${({ theme: { colors } }) => css`
    color: ${colors.grey[45]};
    display: flex;
    flex-direction: column;
    gap: 24px;

    * {
      animation: ${fadeIn} 1s ease-in-out;
    }

    p {
      text-align: center;
    }
  `}
`;

const Button = styled(_Button)`
  width: auto;
`;
