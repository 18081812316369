/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { LDClient, LDFlagValue, LDEvaluationDetail, LDEvaluationReason } from 'launchdarkly-js-client-sdk';

import { tracker } from 'src/utils/analytics';

/** To Track a LaunchDarkly Experiment:
 * 1. Add the experiment flag and its custom metrics to the EXPERIMENT_FLAGS and ExperimentMetrics objects below
 * 2. Call 'const { value, track } = useExperiment(flag);' in the component(s) where the experiment is applied
 * 3. Use 'value' to handle the experiment variations in the component(s)
 * 4. Call 'track(metric-name);' to report an experiment conversion event to LaunchDarkly
 * 5. Revert steps 1 - 4 once the experiment has ended
 */

export const EXPERIMENT_FLAGS: string[] = [];

// Map of experimental flags to their corresponding custom LD experiment metrics
// eg. {'flag': 'metric'} or {'flag': 'metric-name-1' | 'metric-name-2'}, etc.
export type ExperimentMetrics = {
  'sample-feature-change-flag.experiment': 'metric-name-1' | 'metric-name-2'; // Do not remove this line
};

export type ExperimentFlag = keyof ExperimentMetrics;

type ExperimentVariationDetail = LDEvaluationDetail & {
  reason: LDEvaluationReason & { inExperiment?: boolean };
};

export type ExperimentTracker<Property extends ExperimentFlag> = (
  metric: ExperimentMetrics[Property],
  data?: any,
  metricValue?: number
) => void;

export type UseExperimentFlagReturn = {
  loading: boolean;
  value: LDFlagValue;
  track: ExperimentTracker<ExperimentFlag>;
};

export function useExperiment<Flag extends keyof ExperimentMetrics>(
  featureFlag: Flag
): { loading: boolean; value: LDFlagValue; track: ExperimentTracker<Flag> } {
  const client = useLDClient();
  const flags = useFlags();
  const value = flags[featureFlag];

  const track = useCallback(
    (metric: ExperimentMetrics[Flag], data?: any, metricValue?: number) => {
      if (!client) {
        console.error(
          `Tracking the '${metric}' event for ${featureFlag} was attempted before the LaunchDarkly client was ready.`
        );

        return;
      }

      const { reason = null } = client.variationDetail(featureFlag) as ExperimentVariationDetail;
      if (!reason?.inExperiment && reason?.kind !== 'TARGET_MATCH') {
        console.error(
          `Tracking the '${metric}' event for ${featureFlag} was attempted for a user that isn't targeted by the experiment.`
        );
        return;
      }

      client.track(metric, data, metricValue);
    },
    [client, featureFlag]
  );

  return {
    loading: !client,
    track,
    value,
  };
}

export function trackExperimentImpressions(client: LDClient, experimentFlags: string[] = EXPERIMENT_FLAGS): void {
  // eslint-disable-next-line no-restricted-syntax
  for (const flagKey of experimentFlags) {
    const flag = flagKey as ExperimentFlag;
    const { reason = null, ...variationDetail } = client.variationDetail(flag) as ExperimentVariationDetail;
    if (reason?.inExperiment || reason?.kind === 'TARGET_MATCH') {
      tracker.experimentImpression({ flag, reason, ...variationDetail });
    }
  }
}
