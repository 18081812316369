import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { Product } from 'types/graphql-overrides';

import { tracker } from 'src/utils/analytics';

import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import { useFetchTracker } from 'src/utils/ads/hooks/use-fetch-tracker';

type SponsoredProductHandlersInput = {
  product: Product;
  hasBeenVisible: boolean;
  placement?: ProductRecommendationSection;
};

type SponsoredProductHandlersReturn = {
  handleSponsoredProductClick: () => void;
};

export function useSponsoredProductHandlers({
  product,
  hasBeenVisible,
  placement = ProductRecommendationSection.sponsoredProduct,
}: SponsoredProductHandlersInput): SponsoredProductHandlersReturn {
  const router = useRouter();
  const fetchTracker = useFetchTracker();
  const impressionHasTriggered = useRef(false);

  const { clickUrl, impressionUrl } = product.adTrackers ?? {};

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.moloco,
    section: placement,
  });

  useEffect(() => {
    if (hasBeenVisible && !impressionHasTriggered.current && impressionUrl) {
      fetchTracker(impressionUrl);

      tracker.sponsoredProductImpression({ product, trackerSource });

      impressionHasTriggered.current = true;
    }
  }, [hasBeenVisible, impressionUrl, fetchTracker, product, trackerSource]);

  const handleSponsoredProductClick = useCallback(() => {
    if (clickUrl) {
      fetchTracker(clickUrl);
      tracker.sponsoredProductClicked({ product, trackerSource });
      tracker.setContext({ activePromotion: { product, trackerSource } });
    }
  }, [clickUrl, fetchTracker, product, trackerSource]);

  return {
    handleSponsoredProductClick,
  };
}
