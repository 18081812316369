import { useQuery } from '@apollo/react-hooks';
import { useObserver } from 'mobx-react-lite';
import _ from 'lodash';

import { getCategoriesForMenuForState } from 'shared/helpers/products';
import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useCart from 'hooks/use-cart';
import { menuFilters } from 'hooks/use-menu-filter-options';

export default function useDispensaryCategories() {
  const { dispensary } = useDispensary();
  const cart = useCart();
  const menuType = useObserver(() => cart.menuType);
  const { isKiosk } = useUI();

  let categories = getCategoriesForMenuForState(dispensary?.location?.state);
  let loadedCategories = dispensary?.activeCategories || [];

  const { data } = useQuery(menuFilters, {
    skip: !dispensary,
    variables: {
      dispensaryId: dispensary?.id,
      pricingType: menuType,
      isKioskMenu: isKiosk,
    },
  });

  const productCategories = _.get(data, `menuFiltersV2.categoriesSubcategories`, []);

  if (productCategories.length) {
    loadedCategories = productCategories.map(({ category }) => category);
  }

  if (loadedCategories.length) {
    categories = _.filter(categories, (category) => _.includes(loadedCategories, category.value));
  }

  return { categories };
}
