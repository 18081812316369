import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import useTranslation from 'hooks/use-translation';

import { Divider, SubTotal, AdditionalCharges, Total } from 'shared/components/receipt';
import { ModalButton } from 'shared/modals';
import useCart from 'hooks/use-cart';
import useColors from 'hooks/use-colors';
import { DutchiePrivacyFooter } from 'src/dispensary/core-menu/components/dutchie-privacy-footer';
import CaliforniaWarning from 'checkout/components/confirmation/california-warning';

export default function OrderItems(props) {
  const { dispensary, loading, onSubmitOrder } = props;
  const cart = useCart();
  const costBreakdown = useObserver(() => cart.costBreakdown);
  const colors = useColors();
  const { t, Trans } = useTranslation();
  const isDutchiePayOrder = cart.paymentMethod === 'dutchiePay';

  const isCaliforniaDispensary = _.includes(['ca', 'california'], dispensary?.location?.state?.toLowerCase() || '');
  const { receipt } = costBreakdown;

  return (
    <Container>
      <Header>{t('checkout.yourOrder', 'Your Order')}</Header>
      <SubTotal order={costBreakdown} fontSize='14px' color={colors.grey[30]} />{' '}
      <AdditionalCharges
        order={{
          taxAmount: costBreakdown.tax,
          cannabisTax: costBreakdown.cannabisTax,
          paymentFee: costBreakdown.paymentFee,
          salesTaxExistence: costBreakdown.salesTaxExistence,
          cannabisTaxExistence: costBreakdown.cannabisTaxExistence,
          bottleDepositTaxExistence: costBreakdown.bottleDepositTaxExistence,
          receipt,
        }}
        fontSize='14px'
        color={colors.grey[30]}
        taxCopy={t('checkout.salesTax', 'Sales Tax:')}
      />
      <StyledDivider />
      <Total order={{ totalCost: costBreakdown.total }} uppercase={false} fontSize='15px' />
      <Grow>
        <ModalButton loading={loading} onClick={onSubmitOrder} width='100%' mt='30px'>
          {isDutchiePayOrder ? t('checkout.continue', 'Continue') : t('checkout.submitOrder', 'Submit Order')}
        </ModalButton>
      </Grow>
      <Disclaimer>
        <Trans i18nKey='checkout.termsDisclaimer'>
          By submitting this order the customer agrees to our&nbsp;
          <TermsLink href='/terms' target='_BLANK'>
            Terms
          </TermsLink>
          &nbsp;and to receive automated text message updates.
        </Trans>
      </Disclaimer>
      {isCaliforniaDispensary && <CaliforniaWarning isKiosk />}
      <DutchiePrivacyFooterContainer>
        <DutchiePrivacyFooter />
      </DutchiePrivacyFooterContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 45px;
  height: 100%;

  & > * {
    flex-grow: 0;
  }
`;

const Header = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[60]};
  margin-bottom: 23px;
`;

const StyledDivider = styled(Divider)`
  margin: 0px;
`;

const Grow = styled.div`
  flex-grow: 0;
`;

const Disclaimer = styled.div`
  margin-top: 20px;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey[60]};
  line-height: 1.36;
  letter-spacing: 0.1px;
  text-align: center;
`;

const TermsLink = styled.a`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

const DutchiePrivacyFooterContainer = styled.div`
  & > div {
    margin-top: 5px;
    background-color: transparent;
    border-top: none;
    box-shadow: none;
    margin-bottom: 35px;
    width: unset;

    & > p {
      color: ${({ theme }) => theme.colors.grey[60]};
      cursor: pointer;
      font-size: 11px;
    }
  }
`;
