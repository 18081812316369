import _ from 'lodash';

import { paymentMethodsToDisplayNames, DELIVERY, PICKUP } from 'shared/constants';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';

export function getOfferingString(dispensary) {
  const offeringArray = [dispensary.offerDelivery ? DELIVERY : null, dispensary.offerAnyPickupService ? PICKUP : null];
  const offeringString = _.compact(offeringArray).join(' + ');
  return offeringString;
}

export function getPaymentOptions(dispensary) {
  const paymentOptions = [];

  if (!dispensary.cashless) {
    paymentOptions.push({ label: paymentMethodsToDisplayNames.cash, value: 'cash' });
  }

  if (dispensary.creditCard || dispensary.creditCardAtDoor || dispensary.creditCardByPhone) {
    paymentOptions.push({ label: paymentMethodsToDisplayNames.creditCard, value: 'creditCard' });
  }

  return paymentOptions;
}

export function getOrderingOptions(dispensary, openInfo, costBreakdown) {
  if (!openInfo || !dispensary) {
    return [];
  }
  const { orderTypesConfig } = dispensary;
  const { pickup, delivery } = openInfo;

  const pickupIsOpen = !pickup.isClosed || dispensary.storeSettings?.enableAfterHoursOrderingForPickup;
  const deliveryIsOpen =
    !delivery.isClosed ||
    (dispensary.storeSettings?.enableAfterHoursOrderingForDelivery &&
      dispensary.storeSettings?.enableScheduledOrderingForDelivery);

  return _.compact([
    orderTypesConfig.pickup.enabled && {
      label: 'Pickup',
      sublabel: `${orderTypesConfig.curbsidePickup.enabled ? '(In-Store) ' : ''}${
        !pickupIsOpen ? `will reopen at ${pickup.nextService.openString}` : ''
      }`,
      value: 'pickup',
      disabled: !pickupIsOpen,
      dataCyName: 'pickup-option',
    },
    orderTypesConfig.curbsidePickup.enabled && {
      label: 'Pickup',
      sublabel: `(Curbside) ${!pickupIsOpen ? `will reopen at ${pickup.nextService.openString}` : ''}`,
      value: 'curbsidePickup',
      disabled: !pickupIsOpen,
    },
    orderTypesConfig.driveThruPickup.enabled && {
      label: 'Pickup',
      sublabel: `(Drive thru) ${!pickupIsOpen ? `will reopen at ${pickup.nextService.openString}` : ''}`,
      value: 'driveThruPickup',
      disabled: !pickupIsOpen,
    },
    orderTypesConfig.delivery.enabled && {
      label: 'Delivery',
      sublabel:
        costBreakdown?.deliveryFee > 0 // eslint-disable-line
          ? `($${costBreakdown.deliveryFee?.toFixed(2)} Fee)`
          : !deliveryIsOpen
          ? `will reopen at ${delivery.nextService.openString}`
          : null,
      value: 'delivery',
      disabled: !deliveryIsOpen,
      dataCyName: 'delivery-option',
    },
  ]);
}

export function getOrderingOptionsV2(dispensary, openInfo, costBreakdown) {
  if (!openInfo || !dispensary) {
    return [];
  }
  const { orderTypesConfigV2, enabledOrderTypes } = dispensary;
  const { inStorePickup, curbsidePickup, driveThruPickup, delivery } = openInfo;

  const inStoreOpenForPreOrder =
    inStorePickup.isClosed &&
    orderTypesConfigV2?.inStorePickup.enableAfterHoursOrdering &&
    inStorePickup.nextService.openMoment;
  const curbsideOpenForPreOrder =
    curbsidePickup.isClosed &&
    orderTypesConfigV2?.curbsidePickup.enableAfterHoursOrdering &&
    curbsidePickup.nextService.openMoment;
  const driveThruOpenForPreOrder =
    driveThruPickup.isClosed &&
    orderTypesConfigV2?.driveThruPickup.enableAfterHoursOrdering &&
    driveThruPickup.nextService.openMoment;
  const deliveryOpenForPreOrder =
    delivery.isClosed &&
    orderTypesConfigV2?.delivery.enableAfterHoursOrdering &&
    orderTypesConfigV2?.delivery.enableScheduledOrdering &&
    delivery.nextService.openMoment;

  const inStorePickupOpen = inStorePickup.isOpen || inStoreOpenForPreOrder;
  const curbsidePickupOpen = curbsidePickup.isOpen || curbsideOpenForPreOrder;
  const driveThruPickupOpen = driveThruPickup.isOpen || driveThruOpenForPreOrder;
  const deliveryOpen = delivery.isOpen || deliveryOpenForPreOrder;

  const inStorePickupDisabled = !inStorePickupOpen || !inStorePickup.nextService.openMoment;
  const curbsidePickupDisabled = !curbsidePickupOpen || !curbsidePickup.nextService.openMoment;
  const driveThruPickupDisabled = !driveThruPickupOpen || !driveThruPickup.nextService.openMoment;
  const deliveryDisabled = !deliveryOpen || !delivery.nextService.openMoment;

  const nextServiceStringForType = (orderTypeOpenInfo) =>
    orderTypeOpenInfo.nextService.openMoment
      ? `will reopen at ${orderTypeOpenInfo.nextService.openString}`
      : `- ${orderTypeOpenInfo.nextService.openString}`;

  return _.compact([
    enabledOrderTypes.inStorePickup && {
      label: `Pickup`,
      sublabel: `(In-Store) ${
        inStorePickupDisabled || inStoreOpenForPreOrder ? nextServiceStringForType(inStorePickup) : ''
      }`,
      value: `pickup`,
      disabled: inStorePickupDisabled,
      dataCyName: `pickup-option`,
    },
    enabledOrderTypes.curbsidePickup && {
      label: `Pickup`,
      sublabel: `(Curbside) ${
        curbsidePickupDisabled || curbsideOpenForPreOrder ? nextServiceStringForType(curbsidePickup) : ''
      }`,
      value: 'curbsidePickup',
      disabled: curbsidePickupDisabled,
    },
    enabledOrderTypes.driveThruPickup && {
      label: `Pickup`,
      sublabel: `(Drive thru) ${
        driveThruPickupDisabled || driveThruOpenForPreOrder ? nextServiceStringForType(driveThruPickup) : ''
      }`,
      value: 'driveThruPickup',
      disabled: driveThruPickupDisabled,
    },
    enabledOrderTypes.delivery && {
      label: `Delivery`,
      sublabel:
        costBreakdown?.deliveryFee > 0 // eslint-disable-line
          ? `($${costBreakdown.deliveryFee?.toFixed(2)} Fee)`
          : deliveryDisabled || deliveryOpenForPreOrder
          ? nextServiceStringForType(delivery)
          : null,
      value: 'delivery',
      disabled: deliveryDisabled,
      dataCyName: `delivery-option`,
    },
  ]);
}

export function getCartAdditionAccess(dispensary, previewMode) {
  const {
    inStorePickup: inStoreConfig,
    curbsidePickup: curbsideConfig,
    driveThruPickup: driveThruConfig,
    delivery: deliveryConfig,
  } = dispensary?.orderTypesConfigV2;
  const { enabledOrderTypes } = dispensary;

  // It's important that we check whether an order type is even enabled before
  // factoring it's 'After-Hours' status into the overall 'After-Hours' status
  // of the dispensary, otherwise it can lead to misleading UI flows for customers.
  const enableAfterHoursOrderingForPickup =
    (enabledOrderTypes.inStorePickup && inStoreConfig.enableAfterHoursOrdering) ?? false;
  const enableAfterHoursOrderingForCurbsidePickup =
    (enabledOrderTypes.curbsidePickup && curbsideConfig.enableAfterHoursOrdering) ?? false;
  const enableAfterHoursOrderingForDriveThruPickup =
    (enabledOrderTypes.driveThruPickup && driveThruConfig.enableAfterHoursOrdering) ?? false;

  const enableAfterHoursOrderingForDelivery =
    (enabledOrderTypes.delivery && deliveryConfig.enableAfterHoursOrdering) ?? false;
  const enableScheduledOrderingForDelivery = deliveryConfig.enableScheduledOrdering ?? false;

  const openInfo = openInfoForDispensary(dispensary, { previewMode });
  const { inStorePickup, curbsidePickup, driveThruPickup, delivery } = openInfo;

  if (inStorePickup.isOpen || curbsidePickup.isOpen || driveThruPickup.isOpen || delivery.isOpen) {
    return `normal`;
  }

  const hoursClosedIndefinitely = _.every(openInfo, ({ nextService }) => !nextService?.openMoment);

  if (
    !hoursClosedIndefinitely &&
    (enableAfterHoursOrderingForPickup ||
      enableAfterHoursOrderingForCurbsidePickup ||
      enableAfterHoursOrderingForDriveThruPickup ||
      (enableAfterHoursOrderingForDelivery && enableScheduledOrderingForDelivery))
  ) {
    return `afterHours`;
  }
  return `disabled`;
}

export function getIsAfterHoursOrder({ dispensary, openInfo, isDelivery }) {
  if (isDelivery) {
    const { isClosed: isDeliveryClosed } = openInfo.delivery;
    const enableAfterHoursOrderingForDelivery = _.get(
      dispensary,
      `storeSettings.enableAfterHoursOrderingForDelivery`,
      false
    );
    const enableScheduledOrderingForDelivery = _.get(
      dispensary,
      `storeSettings.enableScheduledOrderingForDelivery`,
      false
    );

    return isDeliveryClosed && enableAfterHoursOrderingForDelivery && enableScheduledOrderingForDelivery;
  }

  const { isClosed: isPickupClosed } = openInfo.pickup;
  const enableAfterHoursOrderingForPickup = _.get(dispensary, `storeSettings.enableAfterHoursOrderingForPickup`, false);

  return isPickupClosed && enableAfterHoursOrderingForPickup;
}

export function getIsAfterHoursOrderV2({
  dispensary,
  openInfo,
  isDelivery,
  isInStorePickup,
  isCurbsidePickup,
  isDriveThruPickup,
}) {
  if (isDelivery) {
    const { isClosed: deliveryIsClosed } = openInfo.delivery;
    const enableAfterHoursOrderingForDelivery =
      dispensary?.orderTypesConfigV2?.delivery?.enableAfterHoursOrdering || false;
    const enableScheduledOrderingForDelivery =
      dispensary?.orderTypesConfigV2?.delivery?.enableScheduledOrdering || false;
    return deliveryIsClosed && enableAfterHoursOrderingForDelivery && enableScheduledOrderingForDelivery;
  }

  const {
    inStorePickup: { isClosed: inStoreIsClosed },
    curbsidePickup: { isClosed: curbsideIsClosed },
    driveThruPickup: { isClosed: driveThruIsClosed },
  } = openInfo;

  const enableAfterHoursOrderingForPickup =
    (isInStorePickup && inStoreIsClosed && dispensary?.orderTypesConfigV2?.inStorePickup?.enableAfterHoursOrdering) ||
    (isCurbsidePickup &&
      curbsideIsClosed &&
      dispensary?.orderTypesConfigV2?.curbsidePickup?.enableAfterHoursOrdering) ||
    (isDriveThruPickup &&
      driveThruIsClosed &&
      dispensary?.orderTypesConfigV2?.driveThruPickup?.enableAfterHoursOrdering);

  return enableAfterHoursOrderingForPickup;
}
