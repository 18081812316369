import React, { forwardRef } from 'react';

import useTranslation from 'hooks/use-translation';
import { BaseModal } from '../base-modal';
import { DutchiePayTextBody, DutchiePayButton } from '../../components';

type LearnMoreModalProps = {
  onCloseModal: () => void;
};

export const DutchiePayLearnMoreModal = forwardRef<JSX.Element, LearnMoreModalProps>(({ onCloseModal }, ref) => {
  const { t, Trans } = useTranslation();

  return (
    <BaseModal
      ref={ref}
      onCloseModal={onCloseModal}
      heading={t('dutchiePay.yourAccount', 'Your Dutchie account')}
      hideLogo
    >
      <DutchiePayTextBody>
        <Trans i18nKey='dutchiePay.dutchiePowers'>
          <a href='/'>Dutchie</a> powers online ordering for thousands of cannabis dispensaries, and{' '}
          <strong>you created an account</strong> when you placed an order through Dutchie.
        </Trans>
      </DutchiePayTextBody>

      <DutchiePayTextBody>
        {t(
          'dutchiePay.existingAccount',
          'With an existing account, you’re all set to easily register for Dutchie Pay.'
        )}
      </DutchiePayTextBody>

      <DutchiePayButton onClick={onCloseModal}>{t('dutchiePay.gotIt', 'Got it, thanks!')}</DutchiePayButton>
    </BaseModal>
  );
});
