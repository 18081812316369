import { useState, useEffect, useCallback, useRef } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { GqlDispensaries } from 'types/graphql';

import filteredProducts from 'shared/graphql/product/filtered-products.gql';

import { parseProduct, Product } from 'utils/helpers/product';

import { useXMinds, XMindsClient, RecommendationsResponse } from 'src/services/xminds';
import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

import { useMemoArray } from 'hooks/use-memo-array';

type ProductsFilter = Partial<{
  productIds: string[];
  types: string[];
  sortBy: string;
}>;

type GetProducts = (variables: ProductsVariables) => Promise<Product[]>;

type GetProductsFallback = (productsFilter: ProductsFilter) => ReturnType<GetProducts>;

export type GetRecommendationsFallback = (getProducts: GetProductsFallback) => ReturnType<GetProductsFallback>;

export type GetRecommendations = (
  client: XMindsClient,
  dispensary: GqlDispensaries
) => Promise<RecommendationsResponse>;

export type Options = Partial<{
  skip: boolean;
  limit: number;
  excludeProducts?: string[];
}>;

type ProductsVariables = {
  perPage: number;
  productsFilter: ProductsFilter & {
    bypassOnlineThresholds: boolean;
    dispensaryId: string;
    isKioskMenu: boolean;
    pricingType: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Status: 'Active';
  };
};

type UseGetProductsVariables = {
  dispensary?: GqlDispensaries;
  limit: number;
};

type GetProductsVariables = (productsFilter: ProductsFilter) => ProductsVariables;

type UseProductRecommendationsReturn = {
  data?: Product[];
  error?: Error;
  loading: boolean;
};

function useGetProductsVariables({ dispensary, limit }: UseGetProductsVariables): GetProductsVariables | null {
  const { isKiosk } = useUI();
  const { menuType: pricingType } = useCart();

  const dispensaryId = dispensary?.id;

  const getVariables = useCallback<GetProductsVariables>(
    (productsFilter = {}) => {
      if (!dispensaryId) {
        throw new Error(`missing dispensaryId variable`);
      }

      return {
        productsFilter: {
          sortBy: `inputOrder`,
          bypassOnlineThresholds: isKiosk,
          dispensaryId,
          isKioskMenu: isKiosk,
          pricingType,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Status: `Active`,
          removeProductsBelowOptionThresholds: true,
          ...productsFilter,
        },
        perPage: limit,
      };
    },
    [dispensaryId, isKiosk, limit, pricingType]
  );

  return dispensaryId ? getVariables : null;
}

function useGetProducts(excludeProducts: string[]): GetProducts {
  const apolloClient = useApolloClient();
  const excludeProductsMemo = useMemoArray(excludeProducts);

  return useCallback<GetProducts>(
    async (variables) => {
      const excludeProductsSet = new Set(excludeProductsMemo);
      const filterProduct = ({ id }: Product): boolean => typeof id === 'string' && !excludeProductsSet.has(id);

      const { data } = await apolloClient.query({
        query: filteredProducts,
        variables,
      });

      return data.filteredProducts.products.map(parseProduct).filter(filterProduct);
    },
    [apolloClient, excludeProductsMemo]
  );
}

export function useProductRecommendations(
  getRecommendations: GetRecommendations,
  getRecommendationsFallback: GetRecommendationsFallback,
  options: Options = {}
): UseProductRecommendationsReturn {
  const { skip = false, limit = 10, excludeProducts = [] } = options;

  const { dispensary }: { dispensary?: GqlDispensaries } = useDispensary();

  const xmindsClient = useXMinds();
  const xmindsEnabled = useFlags()['growth.ecomm.personalized-recommendations.xminds-integration.rollout'];

  const getVariables = useGetProductsVariables({ dispensary, limit });
  const getProducts = useGetProducts(excludeProducts);
  const getRecommendationsFallbackRef = useRef(getRecommendationsFallback);

  const [data, setData] = useState<Product[] | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (skip || !dispensary || !getVariables) {
      return;
    }

    void (async () => {
      setLoading(true);

      try {
        if (!xmindsEnabled) {
          throw new Error(`XMinds disabled`);
        }

        if (!xmindsClient) {
          throw new Error(`XMinds client was not initilized properly`);
        }

        const { items_id: productIds } = await getRecommendations(xmindsClient, dispensary);

        if (!productIds.length) {
          throw new Error(`Could not find recommended products`);
        }

        const products = await getProducts(getVariables({ productIds }));

        if (!products.length) {
          throw new Error(`Could not find data for recommended product ids (${productIds.join(', ')})`);
        }

        setData(products);
      } catch (err) {
        if (!window.isTestEnv) {
          // NOTE: consoling for visibility as we polish this feature
          console.warn(err);
        }

        await getRecommendationsFallbackRef
          .current((productFilters) => getProducts(getVariables(productFilters)))
          .then(setData)
          .catch(setError);
      } finally {
        setLoading(false);
      }
    })();
  }, [dispensary, getProducts, getRecommendations, getVariables, skip, xmindsClient, xmindsEnabled]);

  return { data, loading, error };
}
