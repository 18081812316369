import { useRef, useState, RefObject, Dispatch, SetStateAction } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useErnie from 'shared/hooks/use-ernie';
import useUser from 'src/hooks/use-user';
import usePaths from 'src/hooks/use-paths';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useCart from 'src/hooks/use-cart';
import { useHeader } from 'src/dispensary/core-menu/components/header/use-header';
import { useModals, ModalNames } from 'src/components/modals';
import { useGetRewards } from 'src/components/loyalty/use-get-rewards';
import { ERNIE_TYPES } from 'shared/constants';
import { tracker } from 'src/utils/analytics';

type UseUserButtonReturn = {
  isUserLoggedIn: boolean;
  firstName: string;
  isPopoverOpen: boolean;
  popoverTriggerRef: RefObject<HTMLButtonElement>;
  setIsPopoverOpen: Dispatch<SetStateAction<boolean>>;
  loggedInRoutes: { href: string; label: string; hide?: boolean }[];
  loyaltyPoints: string;
  handleLogout: () => void;
  handleLogin: () => void;
  handleSignup: () => void;
  handlePopoverLinkClick: (label: string) => void;
  handlePopoverToggle: (label: string) => void;
  handleGoogleLoginSubmit: () => void;
};

export const useUserButton = (): UseUserButtonReturn => {
  const Cart = useCart();
  const User = useUser();
  const isLoggedIn = useObserver(() => User.isLoggedIn);
  const isLoading = useObserver(() => User.loading);
  const firstName = useObserver(() => User.firstName);
  const birthday = useObserver(() => User.birthday);
  const phone = useObserver(() => User.phone);
  const hasLoyaltyAccount = useObserver(() => User.hasLoyaltyAccount);
  const isUserLoggedIn = isLoggedIn && !isLoading;

  const popoverTriggerRef = useRef<HTMLButtonElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { trackEvent } = useHeader();

  const { openModal } = useModals();
  const showErnie = useErnie();

  const flags = useFlags();
  const isAccountSettingsDutchiePayEnabled = flags['ecomm.menu.account-manage-dutchie-pay.rollout'] ?? false;
  const isShowLoyaltyFlagEnabled = flags['growth.ecomm.show-loyalty-rewards.rollout'] ?? false;

  const { dispensary } = useDispensary();

  const pin = User.getLoyaltyPin(dispensary.id);

  const { data } = useGetRewards({
    dispensaryId: dispensary.id,
    birthday,
    pin,
    phone,
    skip: !pin || !isShowLoyaltyFlagEnabled,
  });

  const rewardsData = data?.getRewardsV2;
  if (rewardsData?.auth?.pinConfirmed) {
    User.hasLoyaltyAccount = true;
    Cart.availableRewards = rewardsData.rewards.map((reward) => ({
      ...reward,
      brand: rewardsData.rewardBrand,
    }));
    Cart.hasRewardsWallet = rewardsData.userHasWallet;
    Cart.rewardsBalance = rewardsData.balance;
    Cart.rewardBrandName = rewardsData.rewardBrand;
    Cart.rewardAuth = rewardsData.auth;
    Cart.hasCheckedForRewards = true;
  }

  const { href: baseHref } = usePaths();
  const loggedInRoutes = [
    {
      href: `${baseHref}/user/account`,
      label: 'My account',
    },
    {
      href: `${baseHref}/user/orders`,
      label: 'Orders',
    },
    {
      href: `${baseHref}/user/loyalty`,
      label: 'Loyalty',
      hide: !isShowLoyaltyFlagEnabled || !dispensary?.consumerDispensaryIntegrations?.alpineiq?.enabled,
    },
    {
      href: `${baseHref}/user/bank-account`,
      label: 'Payments',
      hide: !isAccountSettingsDutchiePayEnabled,
    },
    {
      href: `${baseHref}/user/preferences`,
      label: 'Preferences',
    },
  ];

  const handleLogout = (): void => {
    setIsPopoverOpen(false);
    User.logout();
    showErnie('You’ve been successfully logged out.', ERNIE_TYPES.SUCCESS);
    trackEvent('Logout');
  };

  const handleLogin = async (): Promise<void> => {
    await openModal(ModalNames.login);
    setIsPopoverOpen(false);
    trackEvent('Login');
  };

  const handleSignup = async (): Promise<void> => {
    await openModal(ModalNames.signUp);
    setIsPopoverOpen(false);
    trackEvent('Signup');
  };

  const handlePopoverLinkClick = (label: string): void => {
    setIsPopoverOpen(false);
    trackEvent(label);
  };

  const handlePopoverToggle = (label: string): void => {
    setIsPopoverOpen(!isPopoverOpen);
    trackEvent(label);
  };

  const handleGoogleLoginSubmit = (): void => {
    tracker.gaGTMClickEvent({
      eventCategory: 'account',
      eventAction: 'signed in',
      eventLabel: 'sso: {pageType}',
    });
  };

  return {
    isUserLoggedIn,
    firstName,
    isPopoverOpen,
    popoverTriggerRef,
    setIsPopoverOpen,
    loggedInRoutes,
    loyaltyPoints: hasLoyaltyAccount ? rewardsData?.balance ?? '' : '',
    handleLogout,
    handleLogin,
    handleSignup,
    handlePopoverLinkClick,
    handlePopoverToggle,
    handleGoogleLoginSubmit,
  };
};
