import React, { useMemo, memo } from 'react';
import styled from 'styled-components';
import { focusStyles } from 'src/theme';
import useTranslation from 'hooks/use-translation';
import usePaths from 'hooks/use-paths';
import { useHardAgeGate } from 'hooks/use-hard-age-gate';
import { getCategoryPhoto } from 'shared/helpers/dispensaries';

import Link from 'components/core/link';
import CategoryTile from 'shared/components/category-tile';
import { tracker } from 'src/utils/analytics';

function CategoryTileLink({ category, dispensary, ...props }) {
  const { href, route } = usePaths({ category });
  const { hardAgeGate, dispensaryLogoImage } = useHardAgeGate();
  const { t } = useTranslation();

  const { translatedLabel, imgSrc } = useMemo(
    () => ({
      translatedLabel: t(`common.${category?.label}`, {
        defaultValue: '{{categoryLabel}}',
        categoryLabel: category?.label,
      }),
      imgSrc: hardAgeGate ? dispensaryLogoImage : getCategoryPhoto(dispensary, category),
    }),
    [category, dispensary, hardAgeGate, dispensaryLogoImage]
  );

  return (
    <Link href={href} route={route}>
      <StyledAnchor
        onClick={() =>
          tracker.gaGTMClickEvent({
            eventCategory: 'navigation click',
            eventAction: 'category tile',
            eventLabel: category?.label,
          })
        }
      >
        <CategoryTile imgSrc={imgSrc} label={translatedLabel} {...props} />
      </StyledAnchor>
    </Link>
  );
}
export default memo(CategoryTileLink);

const StyledAnchor = styled.a`
  // apply styles to card on focus
  &.focus-visible {
    outline: none;
    box-shadow: none !important;
    > div {
      ${focusStyles}
    }
  }
`;
