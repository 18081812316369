import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useObserver } from 'mobx-react-lite';
import has from 'lodash/has';
import values from 'lodash/values';

import useUI from 'hooks/use-ui';
import useUser from 'hooks/use-user';
import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useProductQueryParams from 'hooks/use-product-query-params';
import { useDispensariesQueryParams } from 'hooks/use-dispensaries-query-params';
import { isBrowser } from 'utils/misc-utils';

import { useModals, ModalNames } from 'components/modals';

// special short-hand paths that don't pass up a [path] option
const pathOverrides = {
  '/products/[category]': ({ category }) => ({
    category,
  }),
  '/product/[productId]': ({ productId }) => ({
    product: productId,
  }),
};

export function useShowPasswordReset({ router }) {
  const { openModal } = useModals();

  useEffect(() => {
    if (router?.query.resetToken) {
      openModal(ModalNames.changePassword);
    }
  }, [openModal, router?.query.resetToken]);
}

export function useShowLogin({ router }) {
  const { openModal } = useModals();

  useEffect(() => {
    if (router?.query.login) {
      openModal(ModalNames.login);
    }
  }, [openModal, router?.query.login]);
}

export function useShowSignup({ router }) {
  const { openModal } = useModals();

  useEffect(() => {
    if (router?.query.signup) {
      openModal(ModalNames.signUp);
    }
  }, [openModal, router?.query.signup]);
}

export function useSetRoutePath({ UI, router, dispensary }) {
  const { asPath, route, query, isReady } = router;

  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (route === UI.route) {
      UI.externalReferrer = true;
    } else {
      UI.externalReferrer = false;
    }

    UI.setPath(asPath, route);
    const skipScrollToTop = !!query?.['dtche[category]'] ?? !!query.category;
    if (!dispensary?.embedSettings?.disablePageLoadsAtTop && !skipScrollToTop) {
      UI.embeddedEventDispatcher(`iframe:scrollToTop`, {});
    }
  }, [asPath, route, isReady]);

  useEffect(() => {
    if (has(query, `viewOnlyMode`) || has(query, `preview`)) {
      UI.setViewMode(query);
    }
  }, [query]);
}

export function useSetEmbeddedCName({ UI, router }) {
  useEffect(() => {
    if (UI.isEmbedded && router?.query?.cName) {
      UI.setEmbeddedCName(router.query.cName);
    }
  }, [router?.query?.cName, UI]);
}

export function useDefaultMenuLayout({ UI, dispensary }) {
  // trigger menu layout change on dispo updates
  useEffect(() => {
    if (!dispensary || dispensary.id === UI.lastDispensary?.id) {
      return;
    }
    UI.setMenuLayout(dispensary.storeSettings?.defaultViewStyle);
    UI.lastDispensary = dispensary;
  }, [dispensary]);
}

export function useUpdateEmbeddedParentWindow({ UI, router }) {
  const { asPath, route, query, isReady } = router;
  let [basePath] = asPath.split('?');
  const isEmbedded = useObserver(() => UI.isEmbedded);
  const { cName } = query;
  const { queryParamsMinusDefaults: productQueryParams } = useProductQueryParams();
  const { queryParamsMinusDefaults: dispensaryQueryParams } = useDispensariesQueryParams();
  const queryParams = { ...productQueryParams, ...dispensaryQueryParams };

  useEffect(() => {
    if (!isEmbedded) {
      return;
    }
    if (!isReady) {
      return;
    }
    const prefix = `/${UI.dispensaryRootUrl}/${cName}`;
    if (basePath.startsWith(prefix)) {
      let embeddedPath = basePath.replace(prefix, '');
      if (embeddedPath.startsWith('/')) {
        embeddedPath = embeddedPath.replace(/^\/+/, '');
      }
      const routeSegment = route.replace(`/${UI.dispensaryRootUrl}/[cName]`, '');
      if (pathOverrides[routeSegment]) {
        const queryData = pathOverrides[routeSegment](query);
        UI.embeddedEventDispatcher('route:changed', { query: { ...queryParams, ...queryData }, cName });
      } else {
        UI.embeddedEventDispatcher('route:changed', { embeddedPath, query: queryParams, cName });
      }
      return;
    }
    if (basePath.startsWith('/')) {
      basePath = basePath.replace(/^\/+/, '');
    }
    UI.embeddedEventDispatcher('route:changed', { fullPath: basePath, query: queryParams });

    // UI.embeddedEventDispatcher('route:changed', { embeddedPath, queryParams });
  }, [isEmbedded, cName, asPath, isReady, values(queryParams).join()]);
}

function useAgeGateSettings({ UI, dispensary, router, User }) {
  const { bypassAgeGate } = router.query;
  useEffect(() => {
    if (bypassAgeGate) {
      User.setAgeOverride(99, false);
    }
  }, [bypassAgeGate, User]);
  useEffect(() => {
    // this overrides the default logic of when to show the age gate
    // needs dispensary so couldn't be fully calculated in a UI getter
    UI.forceAgeGate = dispensary?.storeSettings?.enableStorefrontAgeGate && UI.isStoreFront;
  }, [UI, dispensary?.storeSettings?.enableStorefrontAgeGate]);
}

function useDispensaryRefetcher() {
  const router = useRouter();
  const { cName } = router.query;
  const { refetch, networkStatus } = useDispensary(cName);
  /*
  don't refetch if we're currently...
   1 - loading a query already
   2 - sort of like loading but related to setVariables
   3 - there's a "fetch more" call in flight
   4 - we're currently refetching already
   5 - ????
   6 - we're polling data
  otherwise refetch
  */
  useEffect(() => {
    if (refetch && networkStatus >= 7) {
      refetch();
    }
  }, [cName, refetch]);
}

function isIframe() {
  if (isBrowser() && window.parent !== window) {
    return true;
  }
  return false;
}

function useIsIframe({ UI }) {
  useEffect(() => {
    UI.isIframe = isIframe();
  }, [isIframe()]);
}

function useSetFirstRender({ UI }) {
  useEffect(() => {
    UI.isFirstRender = false;
  }, [UI]);
}

export default function useUIController() {
  const UI = useUI();
  const User = useUser();
  const router = useRouter();
  const { dispensary } = useDispensary();

  useSetFirstRender({ UI });
  useSetRoutePath({ UI, router, dispensary });
  useSetEmbeddedCName({ UI, router });
  useDefaultMenuLayout({ UI, dispensary });
  useUpdateEmbeddedParentWindow({ UI, router, dispensary });
  useAgeGateSettings({ UI, dispensary, router, User });
  useDispensaryRefetcher();
  useIsIframe({ UI });
  useShowPasswordReset({ router });
  useShowLogin({ router });
  useShowSignup({ router });

  return UI;
}
