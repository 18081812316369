import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import { useSimilarItems, Scenarios, GetRecommendationsFallback } from 'src/recommendations';
import { ProductCarouselProps } from 'src/components/product-carousel';

type UseRelatedItemsCarouselParams = {
  category: string;
  productId: string;
};

type UseRelatedItemsCarouselReturn = Omit<ProductCarouselProps, 'heading'>;

export function useRelatedItemsCarousel({
  category,
  productId,
}: UseRelatedItemsCarouselParams): UseRelatedItemsCarouselReturn {
  const viewportVisibility = useViewportVisibility();

  const getProductsFallback: GetRecommendationsFallback = (getProducts) =>
    getProducts({
      sortBy: `popularSortIdx`,
      types: [category],
    });

  const { data, loading } = useSimilarItems(getProductsFallback, productId, Scenarios.productDetailsPage1, {
    skip: !viewportVisibility.hasBeenVisible,
    limit: 25,
  });

  return {
    products: data ?? [],
    loading,
    viewportVisibility,
  };
}
