import { getFides } from './fides';

import type { ConsentManagementFlag } from './types';

export function getDataSharingEnabledForUser(flag: ConsentManagementFlag | undefined): boolean {
  const Fides = getFides();
  const dataSalesAndSharing = Fides?.consent.data_sales_and_sharing;

  if (flag?.enabled && dataSalesAndSharing !== undefined) {
    return dataSalesAndSharing;
  }

  return true;
}
