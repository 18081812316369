import styled from 'styled-components';

import { DutchiePayLogo } from 'assets/dutchie-pay-logo';
import { DutchiePayButton } from 'src/payments/components';
import Imgix from 'shared/components/imgix';
import { Typography } from 'src/typography';

export const Container = styled.div`
  border-radius: ${(props) => (props.vertical ? `0` : `18px`)};
  margin-top: ${(props) => (props.vertical ? `25px` : `50px`)};
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    border-radius: ${(props) => (props.vertical ? `0` : `8px`)};
    display: flex;
    margin-top: ${(props) => (props.vertical ? `0` : `130px`)};
    max-height: ${(props) => (props.vertical ? `none` : `125px`)};
  }
`;

export const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: ${(props) => !props.vertical && `auto`};
    position: relative;
  }
`;

export const MobileImage = styled(Imgix)`
  height: 100%;
  object-fit: cover;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;

export const DesktopImage = styled(Imgix)`
  height: 100%;
  object-fit: cover;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  padding: ${(props) => (props.vertical ? `20px 35px` : `20px 23px`)};
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: ${(props) => (props.vertical ? `start` : `center`)};
    display: flex;
    flex-direction: ${(props) => props.vertical && `column`};
    gap: 3%;
    height: 100%;
    justify-content: end;
    padding: ${(props) => (props.vertical ? `25px 25px 50px` : `25px`)};
    position: absolute;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    gap: 6%;
  }
`;

export const TextContainer = styled.div`
  color: #fff;
  max-width: 260px;
  text-align: left;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: ${(props) => !props.vertical && `200px`};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: ${(props) => !props.vertical && `260px`};
  }
`;

export const StyledHeading = styled(Typography.Heading)`
  line-height: 20px;
  margin-top: ${(props) => (props.vertical ? `15px` : `8px`)};
  max-width: ${(props) => props.vertical && `200px`};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${(props) => (props.vertical ? `16px` : `19px`)};
    margin: ${(props) => (props.vertical ? `20px 0 5px` : `0 0 5px`)};
    line-height: ${(props) => (props.vertical ? `20px` : `25px`)};
  }
`;

export const StyledBody = styled(Typography.Body)`
  line-height: 1.3;
  margin-top: 3px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: ${(props) => props.vertical && `none`};
    line-height: 1.65;
  }
`;

export const StyledDutchiePayLogo = styled(DutchiePayLogo)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: ${(props) => (props.vertical ? `block` : `none`)};
  }
`;

export const DesktopLogoText = styled.span`
  display: none;
  font-weight: 400;

  & > strong {
    font-weight: 700;
    letter-spacing: -0.75px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: ${(props) => (props.vertical ? `none` : `inline-block`)};
  }
`;

export const MobileLogoText = styled.span`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: ${(props) => (props.vertical ? `inline` : `none`)};
  }
`;

export const Button = styled(DutchiePayButton)`
  height: 39px;
  line-height: 1;
  margin-top: ${(props) => (props.vertical ? `25px` : `16px`)};
  padding: 11px 30px;
  width: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: ${(props) => (props.vertical ? `16px` : `0`)};
  }
`;
