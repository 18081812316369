import React, { useEffect, useCallback, useState } from 'react';
import Script from 'next/script';

import { isBrowser } from 'utils/misc-utils';
import { useConsentManagement } from 'hooks/use-consent-management';
import type { Fides, FidesSDK } from './types';
import { FidesStyles } from './fides.styles';

const isProd = process.env.APP_ENV === 'production';
const privacyCenterUrl = isProd ? 'https://privacy.dutchie.com' : 'https://privacy.dutchie.dev';
const FidesJSUrl = `${privacyCenterUrl}/fides.js`;

export const getFides = (): Fides | undefined => (!isBrowser() ? undefined : global.window.Fides);

export function FidesController(): FidesSDK {
  const { isConsentManagementEnabled } = useConsentManagement();
  const [isLoaded, setIsLoaded] = useState(false);

  const onFidesLoaded = useCallback(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    const fides = getFides();
    if (isConsentManagementEnabled && isLoaded && fides) {
      fides.gtm();
    }
  }, [isConsentManagementEnabled, isLoaded]);

  if (!isConsentManagementEnabled) {
    return null;
  }

  return (
    <>
      <Script key='fides-sdk' src={FidesJSUrl} onLoad={onFidesLoaded} />
      <style data-testid='fides-css'>{FidesStyles}</style>
    </>
  );
}
