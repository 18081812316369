import React, { forwardRef } from 'react';
import { ModalContainer, ModalExitIcon } from 'shared/modals/v2-container';
import styled, { css } from 'styled-components';
import { ModalContent as _ModalContent } from 'components/modals/components';

import { DutchiePayLogo as _DutchiePayLogo } from 'assets/dutchie-pay-logo';
import { Text } from 'rebass';
import { ModalObserverRenderProps } from '../modal-observer/modal-observer.types';
import { MobileEcommDutchiePayCta, MobileEcommDutchiePayButton } from './mobile-ecomm-cta';

type VisitOnDesktopModalProps = ModalObserverRenderProps & {
  isInstoreCart: boolean;
};

export const VisitOnDesktopModal = forwardRef(({ onClose, containerProps }: VisitOnDesktopModalProps, ref) => (
  <ModalContainer data-test='dutchie-pay-relink-bank-modal' ref={ref} padding={0} width={420} {...containerProps}>
    <ModalExitIcon onClick={onClose} />
    <ModalContent>
      <HeaderContainer>
        <DutchiePayLogo width='110' height='20' />
      </HeaderContainer>
      <Heading>Dutchie Pay Signup</Heading>
      <ContentContainer>
        <BodyText>
          <MobileEcommDutchiePayCta initialSignup />
        </BodyText>
      </ContentContainer>
      <MobileEcommDutchiePayButton />
    </ModalContent>
  </ModalContainer>
));

const DutchiePayLogo = styled(_DutchiePayLogo)`
  ${({ theme: { colors } }) => css`
    color: ${colors.dutchiePayGreen};
  `}
`;
const HeaderContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled.div`
  ${({ theme: { spaces } }) => css`
    margin: ${spaces[1]} 0 0 0;
  `}
`;

const Heading = styled.h2`
  ${({ theme: { spaces } }) => css`
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    margin: ${spaces[5]} 0 0 0;
  `}
`;

const BodyText = styled(Text)`
  ${({ theme: { colors, spaces } }) => css`
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: ${spaces[6]};
    color: ${colors.grey[30]};
    :last-child {
      padding-bottom: ${spaces[6]};
    }
  `}
`;

const ModalContent = styled(_ModalContent)`
  ${({ theme: { spaces } }) => css`
    padding: ${spaces[8]};
  `}
`;
