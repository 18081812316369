import { getPersistedValue } from 'shared/utils/persisted-values';
import { EventTracker } from '../events';

const HIGHLIGHT_CSS = 'font-weight: bold; color: #FF3E51; background-color: #153F66; padding: 2px 4px;';

export const createConsoleLogTracker = (): EventTracker => {
  const doLog = !!getPersistedValue('analytics:consoleLogTracker');

  const maybeLog = (method: keyof EventTracker): ((...args: any[]) => void) => (payload: unknown, context: unknown) => {
    if (doLog) {
      console.groupCollapsed(`%cDUTCHIE_ANALYTICS%c   %s`, HIGHLIGHT_CSS, '', method);
      console.info(`%cDUTCHIE_ANALYTICS%c PAYLOAD %o`, HIGHLIGHT_CSS, '', payload);
      console.info(`%cDUTCHIE_ANALYTICS%c CONTEXT %o`, HIGHLIGHT_CSS, '', context);
      console.groupEnd();
    }
  };

  return {
    onContextChange: (update, newContext, oldContext) => {
      if (doLog) {
        console.groupCollapsed(
          `%cDUTCHIE_ANALYTICS%c   %s`,
          HIGHLIGHT_CSS,
          'color: #929394;',
          `onContextChange [${Object.keys(update).join(', ')}]`
        );
        console.info(`%cDUTCHIE_ANALYTICS%c UPDATE %o`, HIGHLIGHT_CSS, '', update);
        console.info(`%cDUTCHIE_ANALYTICS%c NEW CONTEXT %o`, HIGHLIGHT_CSS, '', newContext);
        console.info(`%cDUTCHIE_ANALYTICS%c OLD CONTEXT %o`, HIGHLIGHT_CSS, '', oldContext);
        console.groupEnd();
      }
    },
    accountLogin: maybeLog('accountLogin'),
    addedProductToCart: maybeLog('addedProductToCart'),
    buyItAgainImpression: maybeLog('buyItAgainImpression'),
    buyItAgainViewAllClicked: maybeLog('buyItAgainViewAllClicked'),
    checkoutStarted: maybeLog('checkoutStarted'),
    dutchiePayEnrollmentButtonClicked: maybeLog('dutchiePayEnrollmentButtonClicked'),
    dutchiePayLearnMoreButtonClicked: maybeLog('dutchiePayLearnMoreButtonClicked'),
    dutchiePayCTAButtonClicked: maybeLog('dutchiePayCTAButtonClicked'),
    dutchiePayEnrollmentStep: maybeLog('dutchiePayEnrollmentStep'),
    dutchiePayConnectBankStep: maybeLog('dutchiePayConnectBankStep'),
    dutchiePayPlaidOnEvent: maybeLog('dutchiePayPlaidOnEvent'),
    dutchiePayInstoreAccountLinkViewed: maybeLog('dutchiePayInstoreAccountLinkViewed'),
    dutchiePayInstoreLoginViewed: maybeLog('dutchiePayInstoreLoginViewed'),
    dutchiePayInstoreCartApprovalClicked: maybeLog('dutchiePayInstoreCartApprovalClicked'),
    dutchiePayInstoreCartApprovalFailed: maybeLog('dutchiePayInstoreCartApprovalFailed'),
    dutchiePayInstoreCartApprovalSucceeded: maybeLog('dutchiePayInstoreCartApprovalSucceeded'),
    dutchiePayInstoreCartViewed: maybeLog('dutchiePayInstoreCartViewed'),
    experimentImpression: maybeLog('experimentImpression'),
    paymentsError: maybeLog('paymentsError'),
    placedOrder: maybeLog('placedOrder'),
    productClicked: maybeLog('productClicked'),
    removedProductFromCart: maybeLog('removedProductFromCart'),
    searchedForProduct: maybeLog('searchedForProduct'),
    searchResults: maybeLog('searchResults'),
    viewedCheckout: maybeLog('viewedCheckout'),
    checkoutSession: maybeLog('checkoutSession'),
    viewedProduct: maybeLog('viewedProduct'),
    cartViewed: maybeLog('cartViewed'),
    imageBannerClicked: maybeLog('imageBannerClicked'),
    sponsoredProductImpression: maybeLog('sponsoredProductImpression'),
    sponsoredProductClicked: maybeLog('sponsoredProductClicked'),
    sponsoredBannerImpression: maybeLog('sponsoredBannerImpression'),
    sponsoredBannerClicked: maybeLog('sponsoredBannerClicked'),
    gaGTMClickEvent: maybeLog('gaGTMClickEvent'),
  };
};
