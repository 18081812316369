import { useCallback } from 'react';
import { values } from 'mobx';

import { usePersonalizedItems, Scenarios, GetRecommendationsFallback } from 'src/recommendations';
import { ProductCarouselProps } from 'src/components/product-carousel';

import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import useCart from 'src/hooks/use-cart';

import { OFTEN_PURCHASED_WITH_FILTERS } from 'src/components/carousels';

type UseCartRecommendationsReturn = Omit<ProductCarouselProps, 'heading'>;

function useGetProductsFallback(type: string): GetRecommendationsFallback {
  if (!(type in OFTEN_PURCHASED_WITH_FILTERS)) {
    throw new Error(`missing often purchased with mapping for category: ${type}`);
  }

  const types = OFTEN_PURCHASED_WITH_FILTERS[type];

  return useCallback<GetRecommendationsFallback>((getProducts) => getProducts({ types, sortBy: `type` }), [types]);
}

export function useCartRecommendationsCarousel(): UseCartRecommendationsReturn {
  const { items: cartItems } = useCart();

  const cartItemsArray = values(cartItems);
  const latestProduct = cartItemsArray.slice(-1)[0].product;
  const productIds = cartItemsArray.map((item) => item.id);

  const viewportVisibility = useViewportVisibility();

  const getProductsFallback = useGetProductsFallback(latestProduct.type);

  const { data, loading } = usePersonalizedItems(getProductsFallback, productIds, Scenarios.cart1, {
    skip: !viewportVisibility.hasBeenVisible || !productIds.length,
    limit: 25,
  });

  return {
    products: data ?? [],
    loading,
    viewportVisibility,
  };
}
