import React from 'react';
import Head from 'next/head';

import {
  FaviconAppleTouchIcon,
  Favicon16,
  Favicon32,
  FaviconManifest,
  FaviconSafariPinnedTab,
  FaviconSafariPinnedTabColor,
  Favicon,
  FaviconMsTileColor,
  FaviconMsBrowserConfig,
  FaviconThemeColor,
} from 'src/constants/favicons';

type FaviconsProps = {
  variant: string; // TODO: type possible values, see meta-tags.jsx
};

export default function Favicons(props: FaviconsProps): JSX.Element {
  const { variant } = props;

  // Lookup each favicon value for the current variant, fall back to `default` key when missing
  const faviconAppleTouchIcon = FaviconAppleTouchIcon[variant] ?? FaviconAppleTouchIcon.default;
  const favicon16 = Favicon16[variant] ?? Favicon16.default;
  const favicon32 = Favicon32[variant] ?? Favicon32.default;
  const faviconManifest = FaviconManifest[variant] ?? FaviconManifest.default;
  const faviconSafariPinnedTab = FaviconSafariPinnedTab[variant] ?? FaviconSafariPinnedTab.default;
  const faviconSafariPinnedTabColor = FaviconSafariPinnedTabColor[variant] ?? FaviconSafariPinnedTabColor.default;
  const favicon = Favicon[variant] ?? Favicon.default;
  const faviconMsTileColor = FaviconMsTileColor[variant] ?? FaviconMsTileColor.default;
  const faviconMsBrowserConfig = FaviconMsBrowserConfig[variant] ?? FaviconMsBrowserConfig.default;
  const faviconThemeColor = FaviconThemeColor[variant] ?? FaviconThemeColor.default;

  return (
    <Head>
      <link key='appleTouchIcon' rel='apple-touch-icon' sizes='180x180' href={faviconAppleTouchIcon} />
      <link key='favicon16' rel='icon' type='image/png' sizes='32x32' href={favicon16} />
      <link key='favicon32' rel='icon' type='image/png' sizes='16x16' href={favicon32} />
      <link key='manifest' rel='manifest' href={faviconManifest} />
      <link key='safariPinnedTab' rel='mask-icon' href={faviconSafariPinnedTab} color={faviconSafariPinnedTabColor} />
      <link key='favicon' rel='shortcut icon' href={favicon} />
      <meta key='msTileColor' name='msapplication-TileColor' content={faviconMsTileColor} />
      <meta key='msBrowserConfig' name='msapplication-config' content={faviconMsBrowserConfig} />
      <meta key='themeColor' name='theme-color' content={faviconThemeColor} />
    </Head>
  );
}
