/**
 * @fileOverview See {@link ./README.md}'s "aggregate-tracker.ts" section for details.
 */

import { ContextSetter, createFanOut, PayloadHandlers } from '../helpers/fan-out';
import { Events, AnalyticsContext } from '../events';
import { createConsoleLogTracker } from './console-log-tracker';
import { createInternalGTMTracker } from './internal-gtm-tracker';
import { createSiftTracker } from './sift-tracker';
import { createTelemetryTracker } from './telemetry-tracker';

/**
 * Add configuration details for trackers here.
 */
export type AggregateTrackerConfig = {
  /* FLOW(add-a-tracker).STEP_3a: add your tracker's configuration here */
  internalGTMEnabled: boolean;
  siftBeaconKey: string;
  siftEnabled: boolean;
};

// prettier-ignore
/**
 * Creates an object that looks like:
 * {
 *   addedProductToCart: (payload: Events['addedProductToCart'], context: AnalyticsContext) => void;
 *   placedOrder: (payload: Events['placedOrder'], context: AnalyticsContext) => void;
 *   ...
 *
 *   // to set the context passed to all event handlers
 *   setContext: (context: AnalyticsContext) => void
 * }
 */
export const createAggregateTracker = (
  config?: AggregateTrackerConfig
): ContextSetter<AnalyticsContext> & PayloadHandlers<Events, void> =>
  createFanOut<Events, AnalyticsContext>(
    (fanOut) => ({
      /**
       * Wondering what `fanOut` does? It merely converts
       *   fanOut('foo')
       * to
       *   (payload: Events['foo']) => trackers.forEach((t) => t.foo(payload, context))
       */

      /*
       * FLOW(track-an-event).STEP_2: add your event here (and [STEP_3] in every other *-tracker.ts file in this folder)
       */
      accountLogin: fanOut('accountLogin'),
      addedProductToCart: fanOut('addedProductToCart'),
      buyItAgainImpression: fanOut('buyItAgainImpression'),
      buyItAgainViewAllClicked: fanOut('buyItAgainViewAllClicked'),
      cartViewed: fanOut('cartViewed'),
      checkoutStarted: fanOut('checkoutStarted'),
      dutchiePayCTAButtonClicked: fanOut('dutchiePayCTAButtonClicked'),
      dutchiePayConnectBankStep: fanOut('dutchiePayConnectBankStep'),
      dutchiePayEnrollmentButtonClicked: fanOut('dutchiePayEnrollmentButtonClicked'),
      dutchiePayEnrollmentStep: fanOut('dutchiePayEnrollmentStep'),
      dutchiePayInstoreAccountLinkViewed: fanOut('dutchiePayInstoreAccountLinkViewed'),
      dutchiePayInstoreCartApprovalClicked: fanOut('dutchiePayInstoreCartApprovalClicked'),
      dutchiePayInstoreCartApprovalFailed: fanOut('dutchiePayInstoreCartApprovalFailed'),
      dutchiePayInstoreCartApprovalSucceeded: fanOut('dutchiePayInstoreCartApprovalSucceeded'),
      dutchiePayInstoreCartViewed: fanOut('dutchiePayInstoreCartViewed'),
      dutchiePayInstoreLoginViewed: fanOut('dutchiePayInstoreLoginViewed'),
      dutchiePayLearnMoreButtonClicked: fanOut('dutchiePayLearnMoreButtonClicked'),
      dutchiePayPlaidOnEvent: fanOut('dutchiePayPlaidOnEvent'),
      experimentImpression: fanOut('experimentImpression'),
      gaGTMClickEvent: fanOut('gaGTMClickEvent'),
      imageBannerClicked: fanOut('imageBannerClicked'),
      paymentsError: fanOut('paymentsError'),
      placedOrder: fanOut('placedOrder'),
      productClicked: fanOut('productClicked'),
      removedProductFromCart: fanOut('removedProductFromCart'),
      searchResults: fanOut('searchResults'),
      searchedForProduct: fanOut('searchedForProduct'),
      sponsoredBannerImpression: fanOut('sponsoredBannerImpression'),
      sponsoredBannerClicked: fanOut('sponsoredBannerClicked'),
      sponsoredProductClicked: fanOut('sponsoredProductClicked'),
      sponsoredProductImpression: fanOut('sponsoredProductImpression'),
      viewedCheckout: fanOut('viewedCheckout'),
      checkoutSession: fanOut('checkoutSession'),
      viewedProduct: fanOut('viewedProduct'),
    }),
    {
      activeNavigationBreadcrumbs: null,
      activeMenuVariant: null,
      activeProductPosition: null,
      activeCart: null,
      activeDispensary: null,
      activeOrder: null,
      activePath: null,
      activeUser: null,
      activeIsUserEnrolledDutchiePay: null,
      activePromotion: null,
      checkoutToken: null,
      dutchiePaySessionToken: null,
      featureFlags: {},
    },
    config
      ? [
        /* FLOW(add-a-tracker).STEP_3b: create your tracker here */
        createConsoleLogTracker(),
        createSiftTracker({ beaconKey: config.siftBeaconKey, enabled: config.siftEnabled }),
        createInternalGTMTracker({ enabled: config.internalGTMEnabled }),
        createTelemetryTracker(),
      ]
      : []
  );
