import { diemPortland, oregrown } from '../dispensaries';

export const products = [
  {
    effects: ['Energetic', 'Creative', 'Clear Mind'],
    id: 'cherry-squib',
    subcategory: 'Gummies',
    name: 'Cherry Squib',
    Name: 'Cherry Squib',
    cName: 'cherry-squib',
    type: 'Edible',
    strainType: 'Hybrid',
    Image: 'https://s3-us-west-2.amazonaws.com/dutchie-images/80b40b7f059106375adb388ede83d7b2',
    brand: {
      description:
        // eslint-disable-next-line max-len
        'Lunchbox Alchemy makes every effort to uphold the integrity of a consistent, quality cannabis experience by thoroughly researching all equipment, materials, and partners. Their mission is to craft only the finest cannabis edibles, extracts, and concentrates on the market. Their proprietary process for full-spectrum food grade BHO sets them apart from most edible producers. Their master extractors continue to push the boundaries of quality and innovation, using state-of-the-art, closed-loop systems and the finest medical-grade solvents, filtered and distilled twice in-house to ensure the utmost purity.',
      name: 'Lunchbox Academy',
      id: 'd8a9a6d9-224c-440e-a408-bc56b5e3785c',
      _id: 'd8a9a6d9-224c-440e-a408-bc56b5e3785c',
      aliasList: [],
      isGlobal: false,
      productsCount: 0,
    },
    price: 13.33,
    THCContent: {
      range: [12.5, 17],
      unit: 'PERCENTAGE',
    },
    CBDContent: {
      range: [0.5, 1.7],
      unit: 'PERCENTAGE',
    },
    description: [
      // eslint-disable-next-line max-len
      'These potent gummies from Lunchbox Alchemy will rock your world in a variety of flavors. Utilizing a heavy dosage of food grade BHO to maximize their effects, this intense indica edible has become quite popular for its hard-hitting effects, coupled with its tasty, fruity flavors.',
      // eslint-disable-next-line max-len
      'Made to be enjoyed whole or portioned as needed, each Squib is scored around the edge at even intervals, creating ten triangular pieces with an equal serving/dose of THC. Refer to the instructions on the back of the package to determine what portion is right for you.',
      'One gummy per package, cut to dose.',
    ].join('\n'),
    Options: ['100g'],
    Prices: [13.33],
    medicalPrices: [12.33],
    recPrices: [13.33],
    medicalSpecialPrices: [8.33],
    recSpecialPrices: [8.33],
    special: true,
    dispensaries: [
      {
        ...diemPortland,
        price: 13.33,
        brand: {
          name: 'Resin Ranchers',
        },
        THCContent: {
          range: [12.5],
          unit: 'PERCENTAGE',
        },
        CBDContent: {
          range: [1.7],
          unit: 'PERCENTAGE',
        },
      },
      {
        ...oregrown,
        price: 14.5,
        brand: {
          name: 'Made Up Farm',
        },

        THCContent: {
          range: [15, 17],
          unit: 'PERCENTAGE',
        },
        CBDContent: {
          range: [0.5, 0.7],
          unit: 'PERCENTAGE',
        },
      },
    ],
  },
  {
    effects: ['Energetic', 'Creative', 'Clear Mind'],
    id: 'test-flower-product',
    subcategory: '',
    name: 'Test Flower Product',
    Name: 'Test Flower Product',
    cName: 'test-flower-product',
    type: 'Flower',
    strainType: 'Hybrid',
    Image: 'https://s3-us-west-2.amazonaws.com/dutchie-images/0ee4b0f3ae6296012715128a5b3ff1e1',
    brand: {
      id: 'some-id',
      _id: 'some-id',
      description:
        // eslint-disable-next-line max-len
        'Oregrown description completely made up, not real at all. The best weed ever, smoke it, eat it, drink it, love it. Oregrown description completely made up, not real at all. The best weed ever, smoke it, eat it, drink it, love it.',
      name: 'Oregrown',
      aliasList: [],
      isGlobal: false,
      productsCount: 100,
    },
    price: 18.7,
    THCContent: {
      range: [20.76, 22.19],
      unit: 'PERCENTAGE',
    },
    CBDContent: {
      range: [2.34, 3.51],
      unit: 'PERCENTAGE',
    },
    description: [
      // eslint-disable-next-line max-len
      'According to actor and comedian Seth Rogen, the Pineapple Express cultivar didn’t exist until after the 2008 movie of the same name brought the name into the spotlight. Inspired by the film, growers took it upon themselves to create a potent cultivar that the film described as “the dopest dope.”',
      // eslint-disable-next-line max-len
      'Despite the nebulous marketing and inconsistent reported effects, most cuts of Pineapple Express are sought-after cultivars noted for their fluffy, lean flowers that shine with a lime and yellow coloring. Consumers select Pineapple Express for a tropical, citrus aroma that exudes from its typically higher limonene content.',
    ].join('\n'),
    Options: ['1g', '1/8 oz', '1/4 oz', '1/2 oz', '1 oz', '2oz'],
    // optionsBelowThreshold: ['1/8 oz'],
    Prices: [8, 25, 50, 100, 200, 300.55],
    medicalPrices: [7, 20, 45, 85, 175, 300.55],
    recPrices: [8, 25, 50, 100, 200, 300.55],
    medicalSpecialPrices: [6, 20, 45, 70, 130, 300.55],
    recSpecialPrices: [5, 10, 20, 30, 50, 300.55],
    special: true,
    dispensaries: [
      {
        ...diemPortland,
        price: 13.33,
        brand: {
          name: 'Resin Ranchers',
        },

        THCContent: {
          range: [12.5],
          unit: 'PERCENTAGE',
        },
        CBDContent: {
          range: [1.7],
          unit: 'PERCENTAGE',
        },
      },
      {
        ...oregrown,
        price: 14.5,
        brand: {
          name: 'Made Up Farm',
        },

        THCContent: {
          range: [15, 17],
          unit: 'PERCENTAGE',
        },
        CBDContent: {
          range: [0.5, 0.7],
          unit: 'PERCENTAGE',
        },
      },
    ],
  },
];
