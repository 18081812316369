/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { ApolloError } from 'apollo-client';
import { getDutchiePayErrorMessage, getCreditErrorMessage, dutchiePayReasonCodes } from '@dutchie/error-messaging';
import { ERNIE_TIMEOUTS, ERNIE_TYPES } from 'shared/constants';
import useErnie from 'shared/hooks/use-ernie';
import { ERROR_TYPES } from 'src/payments/constants';
import { useDutchiePay } from 'src/payments/hooks';
import { tracker } from 'utils/analytics';

function formatPaymentsError(
  error: ApolloError | undefined,
  name: string | undefined,
  paymentMethod = ERROR_TYPES.DUTCHIE_PAY
): any {
  const reasonCode = error?.graphQLErrors && (error.graphQLErrors[0]?.extensions?.reason_code as string | undefined);
  const reasonCodeMarkdown = reasonCode ? ` **${String(reasonCode)}**` : ``;
  // Detect if name is null or an empty string
  if (!name || name.trim() === '') {
    name = undefined;
  }
  const errorMessageFormatter =
    paymentMethod === 'credit'
      ? getCreditErrorMessage(reasonCode)
      : getDutchiePayErrorMessage(reasonCode, undefined, name);

  tracker.paymentsError({
    reasonCode: reasonCode && dutchiePayReasonCodes.includes(reasonCode) ? reasonCode : 'REASON_CODE_INVALID',
    meta: error?.graphQLErrors ? error.graphQLErrors[0]?.extensions : null,
  });

  const errorMessage = errorMessageFormatter.ecomm;
  const ernieType = ERNIE_TYPES.DANGER;
  const ernieTimeout = ERNIE_TIMEOUTS.LONG;
  const errorMarkdown = `${String(errorMessage)}${reasonCodeMarkdown}`;

  return { errorMarkdown, errorMessage, ernieType, ernieTimeout, reasonCode };
}

function usePaymentsError(error: ApolloError | undefined): void {
  const showErnie = useErnie();
  const { whiteLabelConfig } = useDutchiePay();

  useEffect(() => {
    if (error) {
      const { errorMarkdown, ernieType, ernieTimout } = formatPaymentsError(
        error,
        whiteLabelConfig.brandedDutchiePayName
      );
      showErnie(errorMarkdown, ernieType, ernieTimout);
    }
  }, [error, showErnie, whiteLabelConfig.brandedDutchiePayName]);
}

export { usePaymentsError, formatPaymentsError };
